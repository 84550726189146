export interface ICell {
	id: string;
	value?: string | ICellObject;
	info: {
		header: string;
	};
}

export interface ICellObject {
	nanoseconds: number;
	seconds: number;
}

export interface IRow {
	cells: ICell[];
	id: string;
}

export interface ITableHeaderItem {
	key: string;
	header: string;
}


// Pagination
const PageSizes = {
	pageSizes: [10, 20, 30, 40, 50]
};

const getItemRangeText = (min: number, max: number, total: number): string => {
	let items: string;
	total === 1 ? (items = 'items') : (items = 'items');
	return `${min} - ${max} of ${total} ${items}`;
};

const getPageRangeText = (current: number, total: number): string => {
	let pages: string;
	total === 1 ? (pages = 'page') : (pages = 'pages');
	return `of ${total} ${pages}`;
};

export { getItemRangeText, getPageRangeText, PageSizes };
