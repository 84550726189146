import React from 'react';
import { HeaderNavigation } from 'carbon-components-react/lib/components/UIShell';
import * as ROUTES from '../utils/routes';
import { NavLink } from 'react-router-dom';
import { AuthContext } from 'components/Auth';

interface INavLink {
	route: string;
	label: string;
}

const NAV_LINKS: INavLink[] = [
	{
		route: ROUTES.ADMIN_ACTIVITY,
		label: 'Activity'
	},
	{
		route: ROUTES.ADMIN_CUSTOMERS,
		label: 'Customers'
	},
	{
		route: ROUTES.ADMIN_CONTRACTORS,
		label: 'Contractors'
	},
	{
		route: ROUTES.ADMIN_LEADS,
		label: 'Leads'
	}
]


const HeaderNavWrapper: React.FunctionComponent<any> = () => {
	const { authUser, isAdmin } = React.useContext(AuthContext);
	return (
		<HeaderNavigation aria-label='Simplyclik'>
			{isAdmin && (
				NAV_LINKS.map((link: INavLink): JSX.Element => (
					<li key={`sc--nav-item-${link.label}`}>
						<NavLink
							role='menuitem'
							className='sc--nav-item bx--header__menu-item'
							activeClassName='active'
							to={link.route}>
							{link.label}
						</NavLink>
					</li>
				))
			)}
		</HeaderNavigation>
	);
}

export {
	HeaderNavWrapper
}
