import React from 'react';
import { LoadingMessage } from '@carbon/ibm-security';

export interface ILoaderProps {
	message?: string;
	className?: string;
}

const Loader: React.FunctionComponent<ILoaderProps> = (props: ILoaderProps) => {

 return (
	 <LoadingMessage
		 active={true}
		 className={props.className}
		 small={false}
		 withOverlay={true}
	 >
		<div className='message'>
			<h6>{props.message || 'Loading...'}</h6>
		</div>
	</LoadingMessage>
 )
}

export {
	Loader
}
