import React from 'react';
import GoogleMapReact from 'google-map-react';
import ContractorContext from './ContractorsContext';
import { ICustomerLocationDetails, IGoogleMarker, ISelectedCustomer, ICustomer } from 'modules/Customers/interface';
import { isEmpty, isEqual } from 'lodash';
import InlineLoading from '@carbon/ibm-security/lib/components/InlineLoading';
import { Dropdown, DropdownSkeleton } from '@carbon/ibm-security/lib/components/Dropdown';
import { mapConsts, setMarkerDetails } from 'utils/map';
import { ContractorMapModal, IContractorMapModal } from './ContractorMapModal';
import { ToastNotification } from '@carbon/ibm-security/lib/components/Notification';
import { ICustomerLocationContractors } from './interface';

interface IMapsContainerState {
	companyNames: ICompanyNames[];
	customerLocationContractorsArray: ICustomerLocationContractors[];
	customerLocationDetails: ICustomerLocationDetails[];
	deviceCenter: {
		lat: number | null;
		lng: number | null;
	};
	geoSet: boolean;
	markerCoords: IGoogleMarker[];
	selectedCustomer: ICustomerLocationDetails[];
	selectedCompanyName: string;
	showNotification: boolean;
}

interface ICompanyNames {
	id: string;
	companyName: string;
}

declare let google: any;

class ContractorMap extends React.Component<any, IMapsContainerState> {
	static defaultProps = {
		center: mapConsts.DEFAULT_LAT_LNG,
		zoom: 10,
		state: 'QLD'
	};
	static contextType = ContractorContext;

	constructor(props) {
		super(props);
		this.state = {
			companyNames: [],
			customerLocationContractorsArray: [],
			customerLocationDetails: [],
			deviceCenter: {
				lat: null,
				lng: null
			},
			geoSet: false,
			markerCoords: [],
			selectedCustomer: [],
			selectedCompanyName: '',
			showNotification: false
		};
	}

	componentDidMount() {
		// this.customerId = this.context.details.id;
		this.context
			.getCompanyNames()
			.then((result) => {
				this.setState({
					companyNames: result
				});
			})
			.catch((err) => {
				console.log(err);
			});
		this.getCurrentLocation();
	}

	getCurrentLocation = () => {
		const addressObj = this.context.details.address;
		const address: string[] = [];
		let urlString: string = '';

		if (addressObj.city) {
			Object.keys(addressObj).forEach((prop) => {
				if (addressObj[prop]) {
					// Check if country has been set if not set Australia as the default
					// Until we support multiple countries this will suffice.
					if (addressObj.country === '') {
						address.push((addressObj.country = mapConsts.DEFAULT_COUNTRY_AUSTRALIA));
					}
					address.push(addressObj[prop]);
				}
				return address;
			});

			urlString = encodeURIComponent(address.join(' '));

			fetch(
				`${mapConsts.BASE_GOOGLE_GEOCODE_URL}?address=${urlString}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
			)
				.then((response) => response.json())
				.then((data) => {
					const { lat, lng } = data.results[0].geometry.location;
					this.setState({
						deviceCenter: {
							lat,
							lng
						},
						geoSet: true
					});
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			this.setDefaultLocation();
		}
	};

	getCurrentCustomerLocationArray = () => {
		const { customerLocationId } = this.state.selectedCustomer[0];

		const customerLocation = this.context.getCustomerLocationContractors.find((customerLocation: ICustomerLocationContractors) => {
			if(Array.isArray(customerLocation.customerLocationId) && customerLocation.customerLocationId[0] === customerLocationId) {
				return customerLocation
			}
			return customerLocation.customerLocationId === customerLocationId;
		});
		if(customerLocation){
			return customerLocation.contractorServices;
		}

	}

	setDefaultLocation = () => {
		this.setState({
			deviceCenter: {
				lat: this.props.center.lat,
				lng: this.props.center.lng
			},
			geoSet: true
		});
	};

	// setMarkerDetails = () => {
	// 	const tempObj: IGoogleMarker[] = [];
	// 	this.state.customerLocationDetails.map((locationObject) => {
	// 		const locationEntry: IGoogleMarker = {
	// 			name: `${this.context.details.companyName} ${locationObject.address.city}`,
	// 			location: {
	// 				lat: locationObject.geometry && locationObject.geometry.location.lat,
	// 				lng: locationObject.geometry && locationObject.geometry.location.lng
	// 			},
	// 			reference: locationObject.reference
	// 		};
	// 		tempObj.push(locationEntry);
	// 	});
	// 	this.setState(
	// 		{
	// 			markerCoords: tempObj
	// 		},
	// 		() => console.log('set marker details', this.state.markerCoords)
	// 	);
	// };

	// getLocationDetails = () => {
	// const customerLocationDetails = this.context
	// 	.getCustomerLocations(this.customerId)
	// 	.then((result) => {
	// 		this.setState(
	// 			{
	// 				customerLocationDetails: result
	// 			},
	// 			() => this.setMarkerDetails()
	// 		);
	// 	})
	// 	.catch((err) => {
	// 		console.log('ERROR: ', err, customerLocationDetails);
	// 	});
	// };

	handleApiLoaded = (map, maps) => {

		const checkSelectedCustomerLocations = () => {
			const customerLatLng = this.state.deviceCenter;
			const selectedCustomer = this.state.customerLocationDetails.filter((location) => {
				return isEqual(customerLatLng, location.geometry?.location)
			})
			this.setState({
				selectedCustomer: selectedCustomer
			})
		}

		this.state.customerLocationDetails.map((location: ICustomerLocationDetails) => {
			const geo = location.geometry && location.geometry.location;
			const title = location.companyName;
			const position = new google.maps.LatLng(geo && geo.lat, geo && geo.lng);

			const marker = new maps.Marker({
				position: position,
				map: map,
				title: title
			});

			marker.addListener('click', () => {
				this.setState({
					selectedCustomer: [],
					deviceCenter: {
						lat: marker.getPosition().lat(),
						lng: marker.getPosition().lng()
					}
				}, () => checkSelectedCustomerLocations());
			});
		});
	};

	closeMarkerModal = () => {
		this.setState({
			selectedCustomer: []
		});
	};

	handleDropdownChange = (value) => {
		this.setState({
			customerLocationDetails: [],
			geoSet: false,
			markerCoords: [],
			selectedCompanyName: value.selectedItem.companyName,
			showNotification: false
		});

		const customerReference = value.selectedItem.id;
		this.context
			.getCustomerLocations(customerReference, this.context.details.address.state || this.props.state)
			.then((response) => {
				if (response.length > 0) {
					const companyData = setMarkerDetails(response);
					this.setState({
						geoSet: true,
						customerLocationDetails: response,
						markerCoords: companyData
					});
				} else {
					this.setState({
						geoSet: true,
						showNotification: true
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	render() {
		const { selectedCustomer } = this.state;

		const markerModalProps: IContractorMapModal = {
			closeMarkerModal: this.closeMarkerModal,
			currentCustomerLocationContractor: this.getCurrentCustomerLocationArray,
			companyName: this.state.selectedCompanyName,
			details: selectedCustomer,
			kind: 'success',
			open: !isEmpty(this.state.selectedCustomer)
		};

		return (
			<React.Fragment>
				<div className='bx--row service-locations-dropdown'>
					<div className='bx--col-lg-4'>
						{isEmpty(this.state.companyNames) ? (
							<DropdownSkeleton />
						) : (
								<Dropdown
									{...{
										id: 'sc--dropdown-company-names',
										className: 'sc--dropdown-company-names',
										label: 'Select company',
										onChange: this.handleDropdownChange,
										titleText: 'Customer',
										itemToString: (item) => (item ? item.companyName : ''),
										items: this.state.companyNames
									}}
								/>
							)}
					</div>
				</div>
				<div className='bx--row'>
					<div className='bx--col-lg-8 sc--map-container'>
						{!this.state.geoSet ? (
							<div className='map-loader'>
								<InlineLoading description='Loading map data...' />
							</div>
						) : (
								<GoogleMapReact
									bootstrapURLKeys={{
										key: process.env.REACT_APP_GOOGLE_API_KEY
									}}
									// defaultCenter={this.props.center}
									center={this.state.deviceCenter}
									defaultZoom={this.props.zoom}
									yesIWantToUseGoogleMapApiInternals
									onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
								/>
							)}
					</div>
					{!isEmpty(this.state.selectedCustomer) && (
						<div className='bx--col-lg-8'>
							<ContractorMapModal {...markerModalProps} />
						</div>
					)}

					{this.state.showNotification && (
						<div className='bx--col-lg-8'>
							<ToastNotification
								title='0 results found'
								subtitle={`${this.state.selectedCompanyName} was not found in this region`}
								kind='warning'
								style={{
									width: 'inherit',
									marginTop: '0'
								}}
								caption={null}
							/>
						</div>
					)}
				</div>
			</React.Fragment>
		);
	}
}

export default ContractorMap;
