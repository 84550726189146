import React from 'react';
import { ToastNotification, Dropdown, SkeletonText } from '@carbon/ibm-security';
import { ICustomerLocationDetails } from 'modules/Customers/interface';
import CustomersContext from './CustomersContext';
import OneTimePasscode, { IOneTimePasscodeProps } from 'components/OneTimePasscode';
import { kebabCase } from 'lodash';


interface IMarkerModalProps {
	closeMarkerModal: () => void;
	kind: string;
	details: ICustomerLocationDetails[];
}

const skeltonProps = {
	heading: true,
	width: '250px'
}

const MarkerModal: React.FunctionComponent<IMarkerModalProps> = (props): JSX.Element => {
	const customerCtx = React.useContext(CustomersContext);
	const [customerValidated, setCustomerOTP] = React.useState<boolean | undefined>();
	const [customerLocationId, setCustomerLocationId] = React.useState<string>('');
	const [currentCustomerDetails, setCurrentCustomerDetails] = React.useState<ICustomerLocationDetails>(props.details[0]);
	const [customerDropdownItems, setCustomerDropdownItems] = React.useState<{id: string; text: string | undefined}[]>();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	React.useEffect(() => {

		if(currentCustomerDetails.docRef){
			setIsLoading(true);
			const validateCustomerLocationOtp = async () => {
				const hasValidated = await customerCtx.checkCustomerLocationOTP(currentCustomerDetails.docRef as string);
				setCustomerOTP(hasValidated);
				setCustomerLocationId(currentCustomerDetails.docRef);
				setIsLoading(false);
			}
			setTimeout(() => {
				validateCustomerLocationOtp();
			}, 1000);
		}

	}, [currentCustomerDetails, setCustomerOTP, setCustomerLocationId, setIsLoading]);



	React.useEffect(() => {
		const items: {id: string; text: string}[] = []
		for(const customerDetails of props.details){
			items.push({ text: customerDetails.contactName, id: `${kebabCase(customerDetails.contactName.toLowerCase())}` })
		}
		setCustomerDropdownItems(items);
	}, []);

	const handleSelectedDropdownOption = React.useCallback(({ selectedItem }) => {
		const newCurrentCustomer = props.details.find((customer) => {
			return customer.contactName === selectedItem.text;
		}) as ICustomerLocationDetails;
		setCurrentCustomerDetails(newCurrentCustomer);
	}, [setCurrentCustomerDetails]);

	const customerLocationDropDown = React.useCallback(() => {
		return (
			<Dropdown
				id='multi-location-drop-down'
				titleText='Operated by: '
				label='Dropdown menu options'
				type='inline'
				light
				items={customerDropdownItems}
				itemToString={(item) => (item ? item.text : '')}
				initialSelectedItem={customerDropdownItems && customerDropdownItems.find((customer) => {
					return customer.text === currentCustomerDetails.contactName
				})}
				onChange={(item) => {
					handleSelectedDropdownOption(item)
				}}
			/>
		);
	}, [currentCustomerDetails.contactName, customerDropdownItems]);

	const subtitleFormat = React.useCallback(() => {
		return (
			<React.Fragment>
				<div className='sc--map-marker-notification--address'>
					<div>{currentCustomerDetails.address.addressLine1}</div>
					{currentCustomerDetails.address && (
						<div>{currentCustomerDetails.address.addressLine2}</div>
					)}
					<div>{`${currentCustomerDetails.address.city}, ${currentCustomerDetails.address.postcode}`}</div>
				</div>
				<div className='sc--map-marker-notification--customer'>
					<div>{customerDropdownItems && customerDropdownItems.length > 1 ? (customerLocationDropDown()) : `Operated by: ${currentCustomerDetails.contactName}`}</div>
					<div>{ isLoading ? <SkeletonText {...skeltonProps}/> : `Phone: ${currentCustomerDetails.contactPhoneNumber}`}</div>
					<div>{ isLoading ? <SkeletonText {...skeltonProps}/> : `Email: ${currentCustomerDetails.contactEmail}`}</div>
					<div>{ isLoading ? <SkeletonText {...skeltonProps}/> : `Reference: ${currentCustomerDetails.reference}`}</div>
				</div>
			</React.Fragment>
		);
	}, [currentCustomerDetails, customerDropdownItems, isLoading]);

	const otpStatus = React.useCallback(() => {
		const oneTimePasscodeProps: IOneTimePasscodeProps = {
			handleRenewOtp: customerCtx.handleRenewOtp,
			collectionId: customerLocationId,
			hasValidated: customerValidated as boolean,
			name: `${currentCustomerDetails.customer.name} ${currentCustomerDetails.address.city}`,
			serviceType: currentCustomerDetails.contactName,
			isLoading: isLoading
		}

		return (
			<OneTimePasscode  {...oneTimePasscodeProps} />
		);
	}, [currentCustomerDetails, customerValidated, customerLocationId, isLoading]);

	return (
		<div className='sc--map-marker-notification'>
			{customerDropdownItems && customerDropdownItems.length >= 1 && (
				<ToastNotification
					caption={otpStatus()}
					notificationType='toast'
					hideCloseButton={false}
					statusIconDescription={'alert'}
					lowContrast
					kind={props.kind}
					onCloseButtonClick={props.closeMarkerModal}
					role='alert'
					style={{
						marginTop: '0',
						width: '100%'
					}}
					subtitle={subtitleFormat()}
					timeout={0}
					title={`${currentCustomerDetails.customer.name} ${currentCustomerDetails.address.city}`}
				/>
			)}
		</div>
	);
};

export { MarkerModal };
