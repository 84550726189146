/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { withFirebase } from 'components/Firebase';
import { Tile } from 'carbon-components-react/lib/components/Tile';
import SkeletonPlaceholder from 'carbon-components-react/lib/components/SkeletonPlaceholder';
import OverflowMenu from '@carbon/ibm-security/lib/components/OverflowMenu'
import OverflowMenuItem from '@carbon/ibm-security/lib/components/OverflowMenuItem'
import { DataChart } from 'components/Chart/DataChart';
import { EMonths } from 'components/Chart';
import { GetDaysOfMonth } from 'utils/common';
import { AuthContext } from 'components/Auth';
import { Link } from 'react-router-dom';
import { DocumentTitle } from 'components/DocumentTitle';
import { EPageTitles } from 'utils/config';
import { DocumentData, QuerySnapshot } from '@firebase/firestore-types';

enum EDateRange {
	WeekAgo = '7',
	TwoWeeksAgo = '14',
	OneMonthAgo = '30'
}

interface ILandingContainerState {
	barDataLabels: string[];
	chartLoading: boolean;
	contractorCount: number;
	customerCount: number;
	leadsCount: number;
	datasets: number[];
	requestCount: number;
	requestCountLabel: string;
	monthByNumber: string;
}

class LandingContainerBase extends React.Component<any, ILandingContainerState> {
	static contextType = AuthContext;
	subscribers = [] as any;

	constructor(props) {
		super(props);
		this.state = {
			barDataLabels: [],
			chartLoading: true,
			contractorCount: 0,
			customerCount: 0,
			datasets: [],
			leadsCount: 0,
			requestCount: 0,
			requestCountLabel: EDateRange.OneMonthAgo,
			monthByNumber: (new Date().getMonth() + 1).toString()
		}
	}

	componentDidMount() {
		document.body.classList.remove('create-flow-open', 'bx--body--with-modal-open');
		if (!this.context.isAdmin) {
			this.props.firebase().doSignOut();
		}
		this.getCustomerCount();
		this.getContractorCount();
		this.getRequests();
		this.getLeadsCount();
		this.getActivityResultsByMonth(EMonths[parseInt(this.state.monthByNumber)])
	}

	componentWillUnmount = (): void => {
		//Remove snapshot listeners on component unmount
		this.subscribers.map((subscriber) => (subscriber()));
	}

	getContractorCount = () => {
		this.props.firebase
			.contractors()
			.get()
			.then((querySnapshot) => {
				this.setState({
					contractorCount: querySnapshot.size
				});
			});
	}

	getCustomerCount = () => {
		this.props.firebase
			.customers()
			.get()
			.then((querySnapshot) => {
				const size = querySnapshot.size;
				this.setState({
					customerCount: size
				});
			});
	}

	getLeadsCount = (): void => {
			const leadsCountRef = this.props.firebase.leads();
			const subscriber = leadsCountRef.onSnapshot({ includeMetadataChanges: true }, (snapshot: QuerySnapshot<DocumentData>) => {
				this.setState({
					leadsCount: snapshot.size
				});
			});
			this.subscribers.push(subscriber);
	}

	getRequests = (value: EDateRange = EDateRange.OneMonthAgo) => {

		const currentDate = new Date();
		let range: number;
		if (value === EDateRange.WeekAgo) {
			range = currentDate.getDate() - parseInt(EDateRange.WeekAgo);
		} else if (value === EDateRange.TwoWeeksAgo) {
			range = currentDate.getDate() - parseInt(EDateRange.TwoWeeksAgo);
		} else if (value === EDateRange.OneMonthAgo) {
			range = currentDate.getDate() - parseInt(EDateRange.OneMonthAgo);
		} else {
			console.log('Error setting Date range')
			return;
		}

		const timeAgo = currentDate.setDate(range);

		this.props.firebase
			.requests()
			.get()
			.then((querySnapshot) => {
				let size: number = 0;
				querySnapshot.forEach((documentSnapshot) => {
					if (documentSnapshot.data().requestStartDate.toMillis() >= timeAgo) {
						size++
					}
				});
				this.setState({
					requestCount: size,
					requestCountLabel: value
				});
			})
			.catch(err => {
				console.log(err)
			})
	}

	getActivityResultsByMonth = (value: string) => {

		this.setState({
			chartLoading: true
		})

		const date = new Date();
		const month = EMonths[value] - 1;
		const firstDayOfMonth = new Date(date.getFullYear(), month, 1);
		const lastDayOFMonth = new Date(date.getFullYear(), month + 1, 0);

		const numberOfDaysArray: number[] = GetDaysOfMonth(EMonths[value] as any as number);

		this.props.firebase
			.requests()
			.where('requestStartDate', '>=', firstDayOfMonth)
			.where('requestStartDate', '<=', lastDayOFMonth)
			.get()
			.then((querySnapshot) => {
				if (querySnapshot.size > 0) {
					const dataset = {};
					querySnapshot.forEach((documentSnapshot) => {
						const requestDay = new Date(documentSnapshot.data().requestStartDate.seconds * 1000).getDate();
						numberOfDaysArray.map((day: number) => {
							if (requestDay === day) {
								{ dataset[requestDay] = (dataset[requestDay] | 0) + 1 }
							} else {
								{ dataset[day] = (dataset[day] | 0) }
							}
						});
					});
					this.setState({
						barDataLabels: numberOfDaysArray.map(String),
						datasets: Object.values(dataset)
					})
				} else {
					this.setState({
						datasets: []
					})
				}

			}).catch((err) => {
				console.log(err)
			})
			.finally(() => {
				this.setState({
					chartLoading: false,
					monthByNumber: EMonths[value]
				})
			})
	}

	render() {
		return (
			<React.Fragment>
				<DocumentTitle title={EPageTitles.Dashboard} />
				<div className={'sc--main-title'}>
					<h1>At a glance...</h1>
				</div>
				<div className='sc--activity-tile-container'>
					<div className='bx--row'>
						<div className='bx--col'>
							{this.state.customerCount >= 0
								? (
									<Link className={'sc--activity-tile'} to='/customers'>
										<Tile>
											<div className='sc--tile-detail'>
												<div className='sc--tile-title'>Customers</div>
												<div className='sc--title-count'>{this.state.customerCount}</div>
											</div>
										</Tile>
									</Link>
								)
								: <SkeletonPlaceholder className='sc--activity-tile-skeleton my--skeleton__placeholder--large' />
							}
						</div>
						<div className='bx--col'>
							{this.state.contractorCount >= 0
								? (
									<Link className={'sc--activity-tile'} to='/contractors'>
										<Tile>
											<div className='sc--tile-detail'>
												<div className='sc--tile-title'>Contractors</div>
												<div className='sc--title-count'>{this.state.contractorCount}</div>
											</div>
										</Tile>
									</Link>
								)
								: <SkeletonPlaceholder className='sc--activity-tile-skeleton my--skeleton__placeholder--large' />
							}
						</div>
						<div className='bx--col'>
							{this.state.leadsCount >= 0
								? (
									<Link className={'sc--activity-tile'} to='/leads'>
										<Tile>
											<div className='sc--tile-detail'>
												<div className='sc--tile-title'>Leads</div>
												<div className='sc--title-count'>{this.state.leadsCount}</div>
											</div>
										</Tile>
									</Link>
								)
								: <SkeletonPlaceholder className='sc--activity-tile-skeleton my--skeleton__placeholder--large' />
							}
						</div>
						<div className='bx--col'>
							{this.state.requestCount >= 0
								? (
									<Tile className={'sc--activity-tile'}>
										<div className='sc--tile-detail'>
											<div className='sc--tile-title'>
												<div>Requests</div>
												<div className='sc--request-menu'>
													<span>{`${this.state.requestCountLabel} days`}</span>
													<OverflowMenu
														flipped={true}
													>
														{Object.keys(EDateRange).map((item) => {
															return (
																<OverflowMenuItem
																	key={`${item}`}
																	itemText={`${EDateRange[item]} days`}
																	onClick={() => this.getRequests(EDateRange[item])}
																/>
															)
														})}
													</OverflowMenu>
												</div>
											</div>
											<div className='sc--title-count'>{this.state.requestCount}</div>
										</div>
									</Tile>
								)
								: <SkeletonPlaceholder className='sc--activity-tile-skeleton my--skeleton__placeholder--large' />
							}
						</div>
					</div>
				</div>
				<div className='bx--row'>
					<div className='bx--col sc--chart-column'>
						{!this.state.chartLoading ? (
							this.state.datasets.length > 0 ? (
								<DataChart {...{
									barData: {
										data: this.state.datasets,
										labels: this.state.barDataLabels
									}
								}} />
							) :
								(
									<React.Fragment>
										<div className='sc--no-data-message'>
											{`No activity for ${EMonths[this.state.monthByNumber]}`}
										</div>
									</React.Fragment>
								)
						)
							: <SkeletonPlaceholder className='sc--activity-tile-skeleton my--skeleton__placeholder--large' />}
						<div className='sc--activity-menu'>
							<span>{`${EMonths[this.state.monthByNumber]}`}</span>
							<OverflowMenu
								flipped={true}
							>
								{Object.keys(EMonths).map((item) => {
									if (typeof EMonths[item] === 'number') return;
									return (
										<OverflowMenuItem
											key={`${item}`}
											itemText={`${EMonths[item]}`}
											onClick={() => this.getActivityResultsByMonth(EMonths[item])}
										/>
									)
								})}
							</OverflowMenu>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

const LandingContainer = withFirebase(LandingContainerBase);

export default LandingContainer;
