import { ICellObject } from 'utils/table';

/* eslint-disable no-undef */
enum ELeadsTableHeaderValues {
	Name = 'Name',
	Received = 'Received',
	Phone = 'Phone',
	Email = 'Email',
	Company = 'Company'
}

enum ELeadsEditSteps {
	Details = 0,
	History = 1,
	Convert = 2
}

enum ELeadEditLabels {
	Details = 'Details',
	History = 'History',
	Convert = 'Convert',
	Delete = 'Delete'
}

enum EScheduleFollowUpErrors {
	TimePickerInputError = 'timePickerInputError',
	DatePickerInputError = 'datePickerInputError'
}
interface INotes {
	date: ICellObject;
	note: string;
}
interface ILead {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	company: string;
	contactPhoneNumber: string;
	notes: INotes[];
	received: {
		seconds: number;
		nanoseconds: number;
	};
}

interface ILeadHistoryInfo {
	date: string;
	message: string;
}

interface ISetScheduleFollowUp {
	followUp: boolean;
	time: string;
	amPm: string;
	date: string;
	message: string;
}

interface ISetScheduleFollowUpErrors {
	timePickerInputError: boolean;
	datePickerInputError: boolean;
}
interface ILeadsInitialState {
	scheduleFollowUp: ISetScheduleFollowUp;
	scheduleFollowUpErrors: ISetScheduleFollowUpErrors;
	leads: ILead[];
	loading: boolean;
	editView: boolean;
	editLead: ILead;
	leadsView?: number;
	convertLead?: string;
}


export {
	ILeadsInitialState,
	ISetScheduleFollowUpErrors,
	ISetScheduleFollowUp,
	ILead,
	INotes,
	EScheduleFollowUpErrors,
	ELeadsTableHeaderValues,
	ELeadsEditSteps,
	ELeadEditLabels
}
