import * as React from 'react';
import { RadioButton, RadioButtonGroup, FormGroup } from '@carbon/ibm-security';

import CustomersContext from '../CustomersContext';

// const CUSTOMER_ADD = 'customer-add';

// interface ILocationsProps {}

const STEP_PREFIX = 'sc--billing';
const billingLabelsTierOne = [
	'Trial',
	'Monthly',
	'Annually'
]
const monthlyLabels = [
	'$40 1-9 facilities (per month per facility)',
	'$30 10-49 facilities (per month per facility)',
	'$20 50-149 facilities (per month per facility)',
	'$15 150+ facilities (per month per facility)'
];
const annualLabels = [
	'$400 1-9 facilities (annually per facility)',
	'$300 10-49 facilities (annually per facility)',
	'$200 50-149 facilities (annually per facility)',
	'$150 150+ facilities (annually per facility)'
];

const radioLabelMap = new Map([
	['Annually', annualLabels],
	['Monthly', monthlyLabels]
]);


const Billing: React.FunctionComponent<{}> = (props) => {
	const customerCtx = React.useContext(CustomersContext);

	const [secondTierButtonGroup, setSecondTierButtonGroup] = React.useState({ enabled: false, group: '' });
	const [defaultRadioTierOne, setDefaultRadioTierOne] = React.useState('Trial');
	const [defaultRadioTierTwo, setDefaultRadioTierTwo] = React.useState('');

	React.useEffect(() => {
		if (customerCtx.details.billing) {
			const { billing } = customerCtx.details;
			if (billing.includes('monthly') || billing.includes('annually')) {
				const defaultValue = billing.split('-').pop() as string;
				const defaultUpper = defaultValue.charAt(0).toUpperCase() + defaultValue.slice(1);
				setSecondTierButtonGroup({ enabled: true, group: defaultUpper });
				setDefaultRadioTierOne(defaultUpper);
				setDefaultRadioTierTwo(customerCtx.details.billing);
			}
		}
	}, []);


	const handleTierOneRadioButtons = React.useCallback((radioValue: string) => {
		if (radioValue === 'Trial') {
			setSecondTierButtonGroup({ enabled: false, group: '' })
		} else {
			setSecondTierButtonGroup({ enabled: true, group: radioValue })
		}
		customerCtx.handleBillingOptions(radioValue);

	}, [setSecondTierButtonGroup]);

	const radioButtonElementsSecondTier = React.useCallback(() => {
		const billingOptions = radioLabelMap.get(secondTierButtonGroup.group);
		if (billingOptions) {
			const radioElements = billingOptions.map(
				(option: string): JSX.Element => {
					return (
						<RadioButton
							defaultChecked={defaultRadioTierTwo}
							disabled={defaultRadioTierTwo === customerCtx.details.billing}
							key={`${option}-option`}
							value={`${option.split(' ')[0]}-${secondTierButtonGroup.group.toLowerCase()}`}
							labelText={option}
						/>
					);
				}
			);
			return radioElements;
		}
		return;

	}, [secondTierButtonGroup, defaultRadioTierTwo]);

	const radioButtonElements = () => {
		const billingOptions = billingLabelsTierOne.map(
			(option: string): React.ReactNode => {
				return (
					<RadioButton
						key={`${option}-option`}
						value={option}
						labelText={option}
					/>
				);
			}
		);
		return billingOptions;
	};

	return (
		<div className='sc--createflow-step billing'>
			<div className='sc--createflow-column'>
				<div>
					<div className={`${STEP_PREFIX}-title title`}>{'Billing'}</div>
					<p className={'sc--body'}>
						{`How will ${customerCtx.details.companyName ||
							'the customer'} be billed for using Simplyclik?`}
					</p>
				</div>
				<div className={`${STEP_PREFIX}--options`}>
					<FormGroup
						legendText='Radio Button heading'
						messageText='huh'
					>
						<RadioButtonGroup
							name={'Billing'}
							orientation={'vertical'}
							onChange={handleTierOneRadioButtons}
							valueSelected={defaultRadioTierOne}>
							{radioButtonElements()}
						</RadioButtonGroup>
					</FormGroup>

					{secondTierButtonGroup.enabled && (
						<FormGroup
							legendText={`${secondTierButtonGroup.group}`}
						>
							<RadioButtonGroup
								name={`${secondTierButtonGroup.group}`}
								orientation={'vertical'}
								onChange={customerCtx.handleBillingOptions}
								// valueSelected={defaultRadioTierTwo}
								defaultSelected={defaultRadioTierTwo}
							>
								{radioButtonElementsSecondTier()}
							</RadioButtonGroup>
						</FormGroup>
					)}

				</div>
			</div>
		</div>
	);
};

export { Billing };
