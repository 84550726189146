/* eslint-disable react/no-unescaped-entities */
import { CreateFlowContainer } from 'components/CreateFlow';
import { EMode } from 'modules/Contractors/interface';
import React from 'react';
import { LeadsContext } from './LeadsContext';
import { ELeadEditLabels, ELeadsEditSteps } from './LeadsInterface';
import { Details, History } from './Steps';
import { ELeadsReducer } from './LeadsReducer';
import { ICalenderEvent } from 'interfaces/calendar';
import ICalendarLink from "react-icalendar-link";
import moment from 'moment';
import { Convert } from './Steps/Convert';

import { Link } from 'react-router-dom'

const leadEditLabels: string[] = Object.values(ELeadEditLabels);

interface IEditLeadProps {
	updateLeadDetails: (leadId: string, message: string) => PromiseLike<any> | undefined;
	loaderHandler: (isLoading: boolean, customMessage?: string) => void;
}

const EditLead: React.FunctionComponent<IEditLeadProps> = (props): JSX.Element | null => {
	const { state, dispatch } = React.useContext(LeadsContext);
	const [currentStep, setCurrentStep] = React.useState<number>(0);
	const [calendarEvent, setCalendarEvent] = React.useState<ICalenderEvent>();
	const [leadPathname, setLeadPath] = React.useState<string>('');
	const shouldEnableSave = React.useCallback((): boolean => {

		if(state.scheduleFollowUp && state.leadsView === ELeadsEditSteps.Details){
			const { scheduleFollowUp } = state;
			if(scheduleFollowUp.message && !scheduleFollowUp.followUp){
				return false //Enable
			} else if(
				scheduleFollowUp.amPm &&
				scheduleFollowUp.date &&
				!state.scheduleFollowUpErrors.datePickerInputError &&
				!state.scheduleFollowUpErrors.timePickerInputError &&
				scheduleFollowUp.message &&
				scheduleFollowUp.time
			) {
				return false;
			} else {
				return true;
			}
		}

		if(state.leadsView === ELeadsEditSteps.History){
			return true;
		}

		if(state.leadsView === ELeadsEditSteps.Convert){
			if(state.convertLead){
				return false;
			}
			return true;
		}

		return false;
	}, [state]);

	const renderMain = React.useCallback((): JSX.Element => {

		if(state.leadsView === ELeadsEditSteps.Details){
			return <Details />
		} else if (state.leadsView === ELeadsEditSteps.History){
			return <History />
		} else if (state.leadsView === ELeadsEditSteps.Convert){
			return <Convert />
		}

		return <></>

	}, [state]);

	const handleCreateFlowView = React.useCallback((show: boolean): void => {
		if(!show) document.body.classList.remove('create-flow-open', 'bx--body--with-modal-open')

		dispatch({
			type: ELeadsReducer.ResetLead
		})
	}, [dispatch]);

	const handleProgressStep = React.useCallback((step: number) => {
		if(step === currentStep){
			setCurrentStep(prevStep => prevStep + 1);
		} else {
			setCurrentStep(step)
		}
	}, [setCurrentStep, currentStep]);


	const isLastStep = React.useCallback((): boolean => {
		return true
	}, []);

	const handleICSGeneration = React.useCallback(() => {
		const { date, time, amPm } = state.scheduleFollowUp;
		const formatDateString: string = date.split('/').reverse().join('-')
		const startTime = moment(formatDateString+' '+ time +' '+ amPm);
		const endTime = moment(startTime).add(1, 'h');
		const event: ICalenderEvent = {
			title: `Simplyclik scheduled meeting with: ${state.editLead.firstName} ${state.editLead.lastName} from ${state.editLead.company}`,
			description: state.scheduleFollowUp.message,
			startTime: startTime.format(),
			endTime: endTime.format(),
			location: ''
		}
		setCalendarEvent(event);

	}, [state, setCalendarEvent]);

	React.useEffect(() => {
		if(calendarEvent){
			const parent = document.getElementById('calendar-wrapper');
			if(parent){
				const hrefEl: HTMLElement = parent.children[0] as HTMLElement;
				hrefEl.click();
			}
				handleCreateFlowView(false);
		}
	}, [calendarEvent, handleCreateFlowView])

	const updateLead = React.useCallback(() => {
		if(state.scheduleFollowUp){
			const updateLeadsPromise = props.updateLeadDetails(state.editLead.id, state.scheduleFollowUp.message) as PromiseLike<any>
			updateLeadsPromise
			.then(() => {
				if(state.scheduleFollowUp.followUp){
					handleICSGeneration();
				} else {
					handleCreateFlowView(false);
				}
			});
		}
	}, [state.scheduleFollowUp, handleCreateFlowView]);

	const leadHandlerFunction = React.useCallback((): CallableFunction | void => {
		if(state.leadsView === ELeadsEditSteps.Details){
			return updateLead();
		} else if (state.leadsView === ELeadsEditSteps.Convert && state.convertLead){
			const divRef: HTMLElement = document.getElementById('link-to-wrapper') as HTMLElement;
			const linkRef: HTMLElement = divRef.childNodes[0] as HTMLElement;
			if(linkRef){
				linkRef.click()
			}
		}
	}, [state]);

	React.useEffect(() => {
		if(state.convertLead){
			const pathname = `/${state.convertLead}s/${state.convertLead}-add`;
			setLeadPath(pathname);
		}
	}, [state.convertLead, setLeadPath])

	if(state.leadsView !== undefined){
		return (
			<React.Fragment>
				{calendarEvent && (
					<div id={'calendar-wrapper'}>
						<ICalendarLink
							filename={'SimplyClik event.ics'}
							event={calendarEvent}
						/>
					</div>
				)}
				{leadPathname && (
				<div id={'link-to-wrapper'}>
					<Link
						to={{
							pathname: leadPathname,
							state: state.editLead
						}}
					/>
				</div>
				)}
				<CreateFlowContainer {...{
					title: 'Lead contact',
					currentStep,
					handleCreateFlowView,
					handleProgressStep,
					leadHandlerFunction,
					isLastStep,
					mode: EMode.EDIT,
					shouldEnableSave,
					stepsType: [leadEditLabels[state.leadsView as number]],
					renderMain: renderMain
				}} />
			</React.Fragment>
		)
	}

	return null;
};

export {
	EditLead
}
