import React from 'react';

export const NoMatch: React.FunctionComponent= (props) => {

	return (
		<React.Fragment>
			<div className={'sc--main-title'}>
					<h1>Page not found</h1>
				</div>
		</React.Fragment>
	)
}
