import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from 'components/Auth';

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
	const { authUser } = React.useContext(AuthContext);
	const auth = authUser ? authUser : !!authUser;

	return (
		<Route
			{...rest}
			render={routerProps =>
				auth ? (
					<RouteComponent {...routerProps} />
				) : (
					<Redirect to={'/'} />
				)
			}
		/>
	);
}

export default PrivateRoute;
