import React from 'react';
import Popup from '@carbon/icons-react/es/popup/16';
import DataTable from 'carbon-components-react/lib/components/DataTable';
import Pagination from '@carbon/ibm-security/lib/components/Pagination';
import DataTableSkeleton from 'carbon-components-react/lib/components/DataTableSkeleton';
import Button from 'carbon-components-react/lib/components/Button';
import { getItemRangeText, getPageRangeText, ITableHeaderItem, IRow, ICell } from 'utils/table';
import { IPagination } from 'interfaces/Pagination';
import { PageSizes } from 'utils/table';
import { ICustomer, ETableHeaderMap } from 'modules/Customers/interface';
import { IActivityTableRow, ICustomerSummary } from './interface';
import { FormatMoney } from 'utils/common';

const activityPrefix = 'sc--activity'
const {
	Table,
	TableContainer,
	TableHead,
	TableHeader,
	TableBody,
	TableCell,
	TableRow
} = DataTable;
const headers: ITableHeaderItem[] = [
	{
		header: ETableHeaderMap.NAME,
		key: 'name'
	},
	{
		header: ETableHeaderMap.OPEN_REQUESTS,
		key: 'openRequests'
	},
	{
		header: ETableHeaderMap.CLOSED_REQUESTS,
		key: 'closedRequests'
	},
	{
		header: ETableHeaderMap.INVOICED_TOTALS,
		key: 'invoiceTotals'
	},
	{
		header: '',
		key: ETableHeaderMap.MAGNIFY
	}
];

export interface IActivityCustomersSummaryTableProps {
	setActivityDetailParams: (customerId: string, customerName: string) => void;
	rows: ICustomerSummary[];
	loading: boolean;
	showSummaryTable: boolean;
}

interface IActivityCustomersSummaryTableState {
	name: string;
	pagination: IPagination;
}

class ActivityCustomersSummaryTable extends React.Component<
	IActivityCustomersSummaryTableProps,
	IActivityCustomersSummaryTableState
	> {
	constructor(props: IActivityCustomersSummaryTableProps) {
		super(props);
		this.state = {
			name: '',
			pagination: {
				pageSize: PageSizes.pageSizes[0],
				page: 1
			}
		};
	}

	filterRows = (rows: ICustomer[]) => {
		const startIndex =
			(this.state.pagination.page - 1) * this.state.pagination.pageSize;
		const endIndex =
			this.state.pagination.page * this.state.pagination.pageSize;
		return rows.filter((row: ICustomer, index: number) => {
			if (index >= startIndex && index < endIndex) return true;
			return false;
		});
	};

	handlePageChange = (pageChange: { page: number; pageSize: number }): void => {
		this.setState((prevState) => {
			return {
				pagination: {
					...prevState.pagination,
					page: pageChange.page,
					pageSize: pageChange.pageSize
				}
			};
		});
	};

	constructTableRows = (rows: ICustomerSummary[]): IActivityTableRow[] => {
		const rowData: IActivityTableRow[] = [];

		for(const row of rows){
			const tempRow = {
				id: row.customerId,
				name: row.customerName,
				openRequests: row.countOfOpenRequest,
				closedRequests: row.countOfCompletedRequest,
				invoiceTotals: row.sumOfInvoiceAmount
			}
			rowData.push(tempRow)
		}

		return rowData;
	}

	render() {

		const { rows } = this.props;
		let tableRows: IActivityTableRow[] = []

			if (typeof rows !== 'undefined') {
				tableRows = this.constructTableRows(rows);
			}

		return (
			<React.Fragment>
				<div className={`${activityPrefix}-table sc--table-wrapper bx--data-table--zebra`}>
					<DataTable
						rows={tableRows}
						isSortable={true}
						headers={headers}
						render={({ rows, headers, getHeaderProps }) => (
							<TableContainer>
								{!this.props.loading ? (
									<React.Fragment>
										<Table>
											<TableHead className={'sc--table-header'}>
												<TableRow>
													{headers.map((header: ITableHeaderItem) => (
														<TableHeader
															{...getHeaderProps({ header })}
															key={header.key}
															>
															{header.header}
														</TableHeader>
													))}
												</TableRow>
											</TableHead>
											<TableBody>
												{rows.map((row: IRow) => (
													<TableRow key={row.id} id={row.id} className={'sc--table-row'}>
														{row.cells.map((cell: ICell) => {
															if (cell.info.header === 'Magnify') {
																return (
																	<TableCell key={cell.id}>
																		<Button
																			className={'sc--edit-table-icon'}
																			iconDescription={'popup icon'}
																			kind={'ghost'}
																			renderIcon={Popup}
																			onClick={() => this.props.setActivityDetailParams(row.id, row.cells[0].value as string)}
																			/>
																	</TableCell>
																);
															} else {
																return (
																	<TableCell key={cell.id}>
																		{cell.info.header === 'invoiceTotals' ? (`$${FormatMoney(cell.value)}`) : cell.value}
																	</TableCell>
																)
															}
														})}
													</TableRow>
												))}
											</TableBody>
										</Table>
										<Pagination
											backwardText='Previous page'
											disabled={false}
											forwardText='Next page'
											isLastPage={false}
											itemRangeText={getItemRangeText}
											itemsPerPageText='Items per page:'
											onChange={this.handlePageChange}
											page={this.state.pagination.page}
											pageInputDisabled
											pageNumberText='Page Number'
											pageRangeText={getPageRangeText}
											pageSize={this.state.pagination.pageSize}
											pageSizes={PageSizes.pageSizes}
											totalItems={rows.length}
										/>
									</React.Fragment>
								) : (
										<DataTableSkeleton />
									)}
							</TableContainer>
						)}
					/>
				</div>
			</React.Fragment>
		);
	}
}

export default ActivityCustomersSummaryTable;
