/* eslint-disable no-useless-escape */
import React from 'react';
import Loading from '@carbon/ibm-security/lib/components/Loading';
import { CreateFlowContainer } from 'components/CreateFlow';
import { Details, Locations, Manage } from './Steps';
import ContractorContext from './ContractorsContext';
import { EFlowLabels, EMode } from './interface';
import { RegexFormat } from 'utils/common';

export interface IContractorEditProps {
	handleCreateFlowView: (hide: boolean, mode: string) => void;
	checkContractorOTP: (contractorDocReference: string) => boolean;
	editContractorDataChanged: () => boolean;
	loaderState: boolean;
	updateContractorDetails: () => void;
}

export interface IContractorEditState {
	docReferencePath: string;
	hasValidated: boolean;
	currentStep: number;
	isServiceEmailInvalid: boolean;
	contractorServiceEmail: string;
}

const addProgressStepLabels = Object.values(EFlowLabels);

export default class ContractorEdit extends React.Component<IContractorEditProps, IContractorEditState> {
	static contextType = ContractorContext;

	constructor(props: IContractorEditProps) {
		super(props);

		this.state = {
			contractorServiceEmail: '',
			docReferencePath: '',
			isServiceEmailInvalid: false,
			hasValidated: false,
			currentStep: 0
		};
	}

	componentDidMount() {
		if(this.context.mode === EMode.EDIT){
			this.shouldEnableSave();
		}
		this.checkContractorOTPStatus();
		this.setState({
			contractorServiceEmail: this.context.details.serviceContactEmail
		})
	}


	handleProgressStep = (step: number, event?: Event) => {
		// console.log(this.props.ContractorDetails);
		let nextStep: number = step;
		if (step === this.state.currentStep) {
			nextStep = step + 1;
			this.setState({
				currentStep: nextStep
			});
		} else {
			this.setState({
				currentStep: step
			});
		}
	};

	handleServiceEmailInvalid = (isValid: boolean) => {
		this.setState({
			isServiceEmailInvalid: isValid
		})
	}

	checkContractorOTPStatus = async() => {
		const docReferencePath = `contractors/${this.context.details.id}`
		const contractorOtpValidated = await this.props.checkContractorOTP(docReferencePath);

		this.setState({
			docReferencePath,
			hasValidated: contractorOtpValidated
		});
	}

	isLastStep = () =>{
		return this.state.currentStep === addProgressStepLabels.length - 1;
	}

	shouldEnableSave = (): boolean => {
		let buttonDisabled: boolean = true;

		if(this.validateSteps()){
			return buttonDisabled;
		}

		if(this.isLastStep() && this.props.editContractorDataChanged()){
			return buttonDisabled;
		}
		return buttonDisabled = false;
	}

	validateSteps = (): boolean => {
		const contractorDetails = this.context.details;
		if(
			contractorDetails.companyName === '' ||
			contractorDetails.contactEmail === '' ||
			!contractorDetails.contactEmail.match(RegexFormat.email) ||
			contractorDetails.contactName === '' ||
			contractorDetails.contactPhoneNumber === '' ||
			!contractorDetails.contactPhoneNumber.match(RegexFormat.phoneNumber) ||
			contractorDetails.abn === '' ||
			!contractorDetails.abn.match(RegexFormat.abn) ||
			contractorDetails.address.addressLine1 === '' ||
			contractorDetails.address.city === '' ||
			contractorDetails.address.postcode === '' ||
			!contractorDetails.address.postcode.match(RegexFormat.postCode) ||
			contractorDetails.address.state === '' ||
			contractorDetails.serviceContactEmail === '' ||
			!contractorDetails.serviceContactEmail.match(RegexFormat.email) ||
			contractorDetails.serviceContactName === ''
		){
			return true
		}

		return false;
	}

	handleServiceEmail = (value: string): void => {
		this.setState({
			contractorServiceEmail: value
		})
	}

	public render() {

		const manageProps = {
			docReferencePath: this.state.docReferencePath,
			hasValidated: this.state.hasValidated,
			handleServiceEmailInvalid: this.handleServiceEmailInvalid,
			handleServiceEmail: this.handleServiceEmail,
			currentServiceContactEmail: this.state.contractorServiceEmail
		}

		return (
			<React.Fragment>
				<Loading active={this.props.loaderState} withOverlay={true} />
				<CreateFlowContainer
					{...{
						title: 'Edit Contractor',
						currentStep: this.state.currentStep,
						handleProgressStep: this.handleProgressStep,
						handleCreateFlowView: this.props.handleCreateFlowView,
						isLastStep: this.isLastStep,
						mode: EMode.EDIT,
						shouldEnableSave: this.shouldEnableSave,
						stepsType: addProgressStepLabels,
						updateContractor: this.props.updateContractorDetails,
						renderMain: () => (
							<React.Fragment>
								{this.state.currentStep === 0 && <Details />}
								{this.state.currentStep === 1 && <Locations />}
								{this.state.currentStep === 2 && <Manage {...manageProps}/>}
							</React.Fragment>
						)
					}}
				/>
			</React.Fragment>
		);
	}
}
