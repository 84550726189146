/* eslint-disable no-undef */
interface ICustomer {
	address: ICustomerAddress;
	billing: string;
	companyName: string;
	contactEmail: string;
	contactName: string;
	contactPhoneNumber: string;
	id: string;
	logo: string;
	paymentEmail: string;
	serviceContactEmail: string;
	serviceContactName: string;
	serviceContactPhone: string;
}

interface ICustomerAddress {
	addressLine1: string;
	addressLine2: string;
	city: string;
	country: string;
	postcode: string;
	state: string;
}

interface ICustomerLocationDetails {
	address: ICustomerAddress;
	billing: string;
	companyName: string;
	contactEmail: string;
	contactName: string;
	contactPhoneNumber: string;
	customer: ICustomerReference;
	geometry?: {
		location: ICoords;
	};
	docRef: string;
	customerLocationId?: string;
	id: string;
	reference: string;
}

interface ICustomerLocation {
	address: ICustomerAddress;
	customer: {
		id: string;
		logoUrl?: string;
		name: string;
	};
	geometry: {
		location: ICoords;
	};
	serviceContactEmail: string;
	serviceContactName: string;
	docRef?: string;
}

interface ICustomerLocations {
	address: ICustomerAddress;
	companyName: string;
	contactEmail: string;
	contactName: string;
	contactPhoneNumber: string;
	serviceContactEmail: string;
	serviceContactName: string;
}

interface ICoords {
	lat?: number | string;
	lng?: number | string;
}

interface IName {
	name: string;
}

interface IGoogleMarker {
	name: string;
	location: ICoords;
	reference: string;
}

interface ISelectedCustomer extends ICustomerLocationDetails, IName {}

interface ICustomerReference {
	id: string;
	logoUrl: string;
	name: string;
	paymentEmail: string;
}

interface ICustomerCtx {
	details: ICustomer;
}
enum ETableHeaderMap {
	NAME = 'Name',
	CONTACT = 'Contact',
	CUSTOMER = 'Customer',
	LOCATION = 'Location',
	PHONE = 'Phone',
	EDIT = 'Edit',
	EMAIL = 'Email',
	EMPTY = '',
	MAGNIFY = 'Magnify',
	OPEN_REQUESTS = 'Open requests',
	STORE_REFERENCE = 'Store #',
	CLOSED_REQUESTS = 'Closed requests',
	INVOICED_TOTALS = 'Invoiced totals'
}

enum ECustomerFlowSteps {
	DETAILS = 0,
	LOCATIONS = 1,
	MANAGE = 2,
	BILLING = 3
}

enum ECustomerFlowLabels {
	DETAILS = 'Details',
	LOCATIONS = 'Locations',
	MANAGE = 'Manage',
	BILLING = 'Billing'
}

export {
	ECustomerFlowLabels,
	ECustomerFlowSteps,
	ETableHeaderMap,
	ICustomerAddress,
	ISelectedCustomer,
	ICustomerLocation,
	IGoogleMarker,
	ICoords,
	ICustomer,
	ICustomerCtx as ICustomerBase,
	ICustomerLocationDetails,
	ICustomerLocations
};

