import * as React from 'react';
import * as csv from 'csvtojson';
import { FileUploaderButton, FileUploaderItem } from 'carbon-components-react';

import CustomersContext from '../CustomersContext';
import MapContainer from '../MapContainer'
import { getFileInstance, EFileStatus, setFileStatus } from 'utils/files';
import { ICustomerLocations } from '../interface';
import { FileStatus } from 'carbon-components-react/lib/components/FileUploader/shared';

const STEP_PREFIX = 'sc--location';

interface ILocationsProps {
	fileName?: string;
	fileStatus?: string;
	handleCSVFileState?: (fileStatus: string) => void;
	setCustomerLocationsArray?: (parsedCSVtoJSONArray?: ICustomerLocations[]) => void;
	setFileName?(fileName: string);
}

const Locations: React.FunctionComponent<ILocationsProps> = (props) => {
	const customerCtx = React.useContext(CustomersContext);

	const handleCSVDelete = () => {
		props.setCustomerLocationsArray && props.setCustomerLocationsArray();
		props.setFileName && props.setFileName('')
		props.handleCSVFileState && props.handleCSVFileState(EFileStatus.UPLOADING);
	}

	const handleLocationCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileInstance = getFileInstance(event);
		const reader = new FileReader();
		reader.onload = () => {
			if(props.setFileName) props.setFileName(fileInstance.name)
			csv({
				noheader: false,
				headers: ['companyName', 'contactEmail', 'contactName', 'contactPhoneNumber', 'serviceContactName', 'serviceContactEmail', 'address.addressLine1', 'address.addressLine2', 'address.city', 'address.country', 'address.postcode', 'address.state', 'reference']
			}).fromString(reader.result as string)
			.then((jsonObj: ICustomerLocations[]) => {
				props.setCustomerLocationsArray && props.setCustomerLocationsArray(jsonObj);
			});
		}

		reader.onloadend = () => {
			const selectedFile = document.getElementsByClassName('bx--file__selected-file')[0];

			selectedFile &&
			selectedFile.addEventListener('mouseenter', () => {
				if(props.handleCSVFileState)  props.handleCSVFileState(EFileStatus.EDIT)
			});

			selectedFile.addEventListener('mouseleave', () => {
				if(props.handleCSVFileState)  props.handleCSVFileState(EFileStatus.COMPLETE)
			});

			setTimeout(() => {
				if(props.handleCSVFileState) props.handleCSVFileState(setFileStatus(reader.readyState));
			}, 500);
		}
		reader.readAsText(fileInstance);
	}

	return (
		<div className='sc--createflow-step location'>
			<div className='sc--createflow-column'>
				<div>
					<div className={`${STEP_PREFIX}-title title`}>Service locations</div>
					{customerCtx.mode === 'add' && (
						<React.Fragment>
							<p className={'sc--body'}>Import the service locations associated with the customer by specifying a comma-seperated (CSV) file.</p>
							<div className='sc--createflow-fileupload  csv-upload'>
								<FileUploaderButton
									accept={['.csv']}
									disableLabelChanges={true}
									labelText={'Add file'}
									multiple={false}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										handleLocationCSV(event)
										}}
								/>
								{props.fileName && (
									<FileUploaderItem
									name={props.fileName}
									status={props.fileStatus as FileStatus}
									onDelete={handleCSVDelete}
								/>
								)}
							</div>
						</React.Fragment>
					)}
					{customerCtx.mode === 'edit' && <MapContainer />}
				</div>
			</div>
		</div>
	);
};

export { Locations };
