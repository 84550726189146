/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { withFirebase } from "components/Firebase";
import CustomerAdd from "./CustomerAdd";
import CustomerEdit from "./CustomerEdit";
import CustomersContext from "./CustomersContext";
import CustomersTable from "./CustomersTable";
import { ICustomer, ICustomerBase, ICustomerLocations } from "./interface";
import isEqual from "lodash/isEqual";
import { getFileInstance, EFileStatus } from "utils/files";
import { DocumentTitle } from "components/DocumentTitle";

import ComposedModal, {
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "carbon-components-react/lib/components/ComposedModal";
import Button from "@carbon/ibm-security/lib/components/Button";
import { EMode } from "modules/Contractors/interface";
import { ILead } from "modules/Leads/LeadsInterface";
import { EPageTitles } from "utils/config";

interface ICustomersContainerbase {
  customers: ICustomer[];
  customerLoader: boolean;
  editableCustomer: ICustomer;
  currentCustomerId: string;
  errorMessage: string;
  previousCustomerEmail: string;
  fileUploadStatus: string;
  hasTableData: boolean;
  logoInstanceName: string;
  mode: string;
  newCustomerDetails: ICustomer;
  showCustomerAdd: boolean;
  showCustomerEdit: boolean;
  showWarningModal: boolean;
}

interface ICustomerHandlers {
  checkCustomerLocationOTP: (customerId: string) => boolean;
  getCustomerLocations: (id: string, locationState: string) => void;
  handleCustomerInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleCustomerAddressInputChange: (event: Event) => void;
  handleFileDelete: (modeType: string) => void;
  handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddressPostCode: (type: string, stateId: string) => void;
  handleBillingOptions: (option: string) => void;
  handleRenewOtp: (customerLocationId: string) => boolean;
  fileUploadStatus?: ICustomersContainerbase["fileUploadStatus"];
  logoName: ICustomersContainerbase["logoInstanceName"];
  mode: ICustomersContainerbase["fileUploadStatus"];
  errorMessage: ICustomersContainerbase["errorMessage"];
}

export interface IAddCustomerContext extends ICustomerBase, ICustomerHandlers {}

const DEFAULT_CUSTOMER: ICustomer = {
  address: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    postcode: "",
    state: "",
  },
  billing: "Trial",
  companyName: "",
  contactEmail: "",
  contactName: "",
  contactPhoneNumber: "",
  logo: "",
  id: "",
  paymentEmail: "",
  serviceContactEmail: "",
  serviceContactName: "",
  serviceContactPhone: "",
};
class CustomersContainerBase extends React.Component<
  any,
  ICustomersContainerbase
> {
  //Global used to compare changes for editable customer fileds.
  initCustomerDetails: ICustomer = DEFAULT_CUSTOMER;
  searchInputTimeout;

  constructor(props: ICustomersContainerbase) {
    super(props);
    this.state = {
      customers: [],
      customerLoader: false,
      currentCustomerId: "",
      editableCustomer: DEFAULT_CUSTOMER,
      errorMessage: "",
      previousCustomerEmail:"",
      fileUploadStatus: EFileStatus.UPLOADING,
      hasTableData: false,
      logoInstanceName: "",
      mode: "",
      newCustomerDetails: DEFAULT_CUSTOMER,
      showCustomerAdd: false,
      showCustomerEdit: false,
      showWarningModal: false,
    };
  }

  handleTableSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const TIME_OUT = 1000;
    const value = event.target.value;
    if (this.searchInputTimeout) {
      clearTimeout(this.searchInputTimeout);
    }

    this.setState({
      hasTableData: false,
    });

    this.searchInputTimeout = setTimeout(() => {
      this.filterCustomerByName(value);
    }, TIME_OUT);
  };

  filterCustomerByName = (value: string) => {
    const filteredResults: ICustomer[] = this.state.customers.filter(
      (customer: ICustomer) => {
        const companyName = customer.companyName.toLowerCase();
        const tempValue = value.toLowerCase().trim();
        if (companyName.indexOf(tempValue) !== -1) {
          return true;
        }
        return false;
      }
    );

    if (filteredResults.length > 0) {
      this.setState({
        customers: filteredResults,
        hasTableData: true,
      });
    } else {
      this.setState({
        customers: this.state.customers,
        hasTableData: true,
      });
    }
  };

  componentDidMount() {
    if (this.props.location.pathname === "/customers/customer-add") {
      this.handleNewLeadDetails(this.props.location.state as ILead);
    }
    document.body.classList.remove(
      "create-flow-open",
      "bx--body--with-modal-open"
    );
    this.getTableData();
  }

  /**
   * @function handleNewLeadDetails sets leads details when converting lead to new contractor.
   * @param leadDetails convert lead details which is used to populate initial details fields
   */
  handleNewLeadDetails = (leadDetails: ILead) => {
    this.handleCreateFlowView(true, "add");
    const newLeadDetails = {
      companyName: leadDetails.company,
      contactName: `${leadDetails.firstName} ${leadDetails.lastName}`,
      contactPhoneNumber: leadDetails.contactPhoneNumber,
      contactEmail: leadDetails.email,
    } as ICustomer;

    this.setState((prevState) => {
      return {
        ...prevState,
        newCustomerDetails: {
          ...prevState.newCustomerDetails,
          ...newLeadDetails,
        },
      };
    });
  };

  checkCustomerLocationOTP = (docReferencePath: string): boolean => {
    return this.props.firebase
      .oneTimePasscodes()
      .get()
      .then((querySnapshot) => {
        let hasValidated: boolean = true;
        for (const documentSnapshot of querySnapshot.docs) {
          if (
            documentSnapshot.data().reference.path === `${docReferencePath}`
          ) {
            hasValidated = false;
            break;
          }
        }
        return hasValidated;
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  setEditableCustomer = (customer: ICustomer) => {
    this.initCustomerDetails = customer;
    this.setState(
      {
        currentCustomerId: customer.id,
        editableCustomer: customer,
        previousCustomerEmail: customer.serviceContactEmail,
        mode: "edit",
        showCustomerEdit: true,
      },
      () => this.handleCreateFlowView(true, "edit")
    );
  };

  getCustomerLocations = (id: string, locationState: string) => {
    if (!id) {
      console.error("id in editable customer object not found.");
      return;
    }

    const customerLocationDetails: any = [];
    return this.props.firebase.customerLocations().where("customer.id", "==", id).where("address.state", "==", locationState).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const tempCustomerObj = {
            ...doc.data(),
            docRef: doc.ref.path,
          };
          customerLocationDetails.push({ ...tempCustomerObj });
        });
        return customerLocationDetails;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getTableData = () => {
    this.setState({
      hasTableData: false,
    });

    const customersArray: ICustomer[] = [];
    this.props.firebase.customers().get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const tempObj = Object.assign({ id: doc.id }, doc.data());
          customersArray.push(tempObj);
        });
        this.setState({
          customers: customersArray,
          hasTableData: true,
        });
      });
  };

  handleCustomerInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (this.state.mode === EMode.ADD) {
      this.setState((prevState) => {
        return {
          newCustomerDetails: {
            ...prevState.newCustomerDetails,
            [event.target.name]: event.target.value,
          },
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          editableCustomer: {
            ...prevState.editableCustomer,
            [event.target.name]: event.target.value,
          },
        };
      });
    }
  };

  handleCustomerAddressInputChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const inputType = target.name;
    const value = target.value;

    if (this.state.mode === EMode.ADD) {
      this.setState((prevState) => {
        return {
          newCustomerDetails: {
            ...prevState.newCustomerDetails,
            address: {
              ...prevState.newCustomerDetails.address,
              [inputType]: value,
            },
          },
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          editableCustomer: {
            ...prevState.editableCustomer,
            address: {
              ...prevState.editableCustomer.address,
              [inputType]: value,
            },
          },
        };
      });
    }
  };

  handleBillingOptions = (value: string) => {
    if (this.state.mode === "add") {
      this.setState({
        newCustomerDetails: {
          ...this.state.newCustomerDetails,
          billing: value,
        },
      });
    } else {
      this.setState({
        editableCustomer: {
          ...this.state.editableCustomer,
          billing: value,
        },
      });
    }
  };

  handleAddressPostCode = (type: string, stateId: string) => {
    if (this.state.mode === "add") {
      this.setState((prevState) => {
        return {
          newCustomerDetails: {
            ...prevState.newCustomerDetails,
            address: {
              ...prevState.newCustomerDetails.address,
              [type]: stateId,
            },
          },
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          editableCustomer: {
            ...prevState.editableCustomer,
            address: {
              ...prevState.editableCustomer.address,
              [type]: stateId,
            },
          },
        };
      });
    }
  };

  render() {
    const { mode } = this.state;
    const customerRows: ICustomer[] = this.state.customers;
    const addCustomerCtx: IAddCustomerContext = {
      details:
        mode === "edit"
          ? this.state.editableCustomer
          : this.state.newCustomerDetails,
      errorMessage: this.state.errorMessage,
      checkCustomerLocationOTP: this.checkCustomerLocationOTP,
      fileUploadStatus: this.state.fileUploadStatus,
      logoName: this.state.logoInstanceName,
      getCustomerLocations: this.getCustomerLocations,
      handleAddressPostCode: this.handleAddressPostCode,
      handleBillingOptions: this.handleBillingOptions,
      handleCustomerAddressInputChange: this.handleCustomerAddressInputChange,
      handleCustomerInputChange: this.handleCustomerInputChange,
      handleFileDelete: this.handleFileDelete,
      handleFileUpload: this.handleFileUpload,
      handleRenewOtp: this.handleRenewOtp,
      mode: mode,
    };

    return (
      <CustomersContext.Provider value={addCustomerCtx}>
        <DocumentTitle title={EPageTitles.Customers} />
        <React.Fragment>
          {this.state.showWarningModal && (
            <ComposedModal
              {...{
                className: "sc--composed-modal",
                open: this.state.showWarningModal,
                danger: true,
                onClose: () => this.setState({ showWarningModal: false }),
              }}
            >
              <ModalHeader
                {...{
                  buttonOnClick: () => {
                    this.setState({ showWarningModal: false });
                  },
                  iconDescription: "Close",
                  title: "Warning",
                }}
              />
              <ModalBody>
                <div>
                  <p className="sc--composed-modal--body">
                    You have unsaved changes.
                  </p>
                  <p className="sc--composed-modal--body">
                    If you'd like to keep editing press the Continue editing
                    button.
                  </p>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  {...{
                    kind: "secondary",
                    onClick: () => this.setState({ showWarningModal: false }),
                  }}
                >
                  {"Continue editing"}
                </Button>
                <Button
                  {...{
                    kind: "danger",
                    onClick: () => {
                      this.handleModalClose();
                    }, //close modal and reset fields check file upload
                  }}
                >
                  {"Close without saving"}
                </Button>
              </ModalFooter>
            </ComposedModal>
          )}

          {this.state.showCustomerAdd && (
            <CustomerAdd
              {...{
                customerDetails: this.state.newCustomerDetails,
                createNewCustomer: this.createNewCustomer,
                handleCreateFlowView: this.handleCreateFlowView,
                loaderState: this.state.customerLoader,
              }}
            />
          )}
          {this.state.showCustomerEdit && (
            <CustomerEdit
              {...{
                checkCustomerLocationOTP: this.checkCustomerLocationOTP,
                handleCreateFlowView: this.handleCreateFlowView,
                handleErrorMessage: this.handleErrorMessage,
                hasCustomerDataChanged: this.hasCustomerDataChanged,
                loaderState: this.state.customerLoader,
                updateCustomerDetails: this.updateCustomerDetails,
              }}
            />
          )}
          <div className={"sc--main-title"}>
            <h1>{EPageTitles.Customers}</h1>
          </div>
          <CustomersTable
            {...{
              setEditableCustomer: this.setEditableCustomer,
              rows: customerRows,
              loaded: this.state.hasTableData,
              handleCreateFlowView: this.handleCreateFlowView,
              handleTableSearch: this.handleTableSearch,
            }}
          />
        </React.Fragment>
      </CustomersContext.Provider>
    );
  }

  checkCustomerDetails = (): boolean => {
    const { newCustomerDetails } = this.state;
    let hasChanged = false;
    if (!isEqual(newCustomerDetails, DEFAULT_CUSTOMER)) {
      hasChanged = true;
    }

    return hasChanged;
  };

  hasCustomerDataChanged = (): boolean => {
    const { editableCustomer } = this.state;
    const hasChanged: boolean = !isEqual(
      editableCustomer,
      this.initCustomerDetails
    );

    return hasChanged;
  };

  handleCreateFlowView = (show: boolean, mode: string) => {
    show === false
      ? document.body.classList.remove(
          "create-flow-open",
          "bx--body--with-modal-open"
        )
      : document.body.classList.add("create-flow-open");
    if (mode === "add") {
      if (show === false) {
        if (this.checkCustomerDetails()) {
          this.setState({
            showWarningModal: true,
          });
        } else {
          this.setState({
            mode: "",
            showCustomerAdd: false,
          });
        }
      } else {
        this.setState({
          mode: mode,
          showCustomerAdd: true,
        });
      }
    }

    if (mode === "edit") {
      if (show === false) {
        if (!isEqual(this.state.editableCustomer, this.initCustomerDetails)) {
          this.setState({
            showWarningModal: true,
          });
        } else {
          this.setState({
            mode: "",
            showCustomerEdit: false,
            editableCustomer: (this.initCustomerDetails = DEFAULT_CUSTOMER),
          });
        }
      } else {
        this.setState({
          mode: mode,
          showCustomerEdit: true,
        });
      }
    }
  };

  /**
   * Remove image from firebase storage
   */
  handleFileDelete = (modeType: string) => {
    if (this.state.logoInstanceName === "") {
      return;
    }

    const storageRef = this.props.firebase.storage.ref(
      `images/${this.state.logoInstanceName}`
    );
    storageRef
      .delete()
      .then(() => {
        this.setState((prevState) => {
          return {
            ...prevState,
            logoInstanceName: "",
            fileUploadStatus: EFileStatus.EDIT,
            [modeType]: {
              ...prevState[modeType],
              logo: "",
            },
          };
        });
      })
      .catch((err) => {
        console.log("ERROR deleting file from firebase", err);
      });
  };

  handleRenewOtp = (customerLocationRef: string): boolean => {
    if (!customerLocationRef) {
      return false;
    }

    const customerDocRefArray = customerLocationRef.split("/");

    const renewOtp = this.props.firebase.functions.httpsCallable("renewOtp");
    const data = {
      collection: customerDocRefArray[0],
      document: customerDocRefArray[1],
    };

    return renewOtp(data)
      .then((response) => {
        return true;
      })
      .catch((err) => {
        console.log("error", err);
        return false;
      });
  };

  handleNewCustomerPassword = (request, password) => {
    const sendMailToNewCustomer = this.props.firebase.functions.httpsCallable(
      "createCustomerPassword"
    );
    return sendMailToNewCustomer({
      name: request.serviceContactName,
      email: request.serviceContactEmail,
      password: password,
    })
      .then(() => {
        return true;
      })
      .catch((err) => {
        console.log("error", err);
        return false;
      });
  };
  /**
   * @function handleFileUpload Upload file to firebase storage
   * @param event to get file instance meta info
   */
  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInstance = getFileInstance(event);
    const storageRef = this.props.firebase.storage.ref(
      `images/${fileInstance.name}`
    );
    const task = storageRef.put(fileInstance);
    this.setState({
      logoInstanceName: fileInstance.name,
    });

    task.on(
      "state_changed",
      (snapshot) => {
        console.log(
          "uploading",
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (error) => {
        console.log("ERROR", error);
      },
      () => {
        console.log("COMPLETE");
        const selectedFile = document.querySelector(
          ".bx--file__state-container"
        );

        selectedFile &&
          selectedFile.addEventListener("mouseenter", (event) => {
            this.setState({
              fileUploadStatus: EFileStatus.EDIT,
            });
          });

        selectedFile &&
          selectedFile.addEventListener("mouseleave", (event) => {
            this.setState({
              fileUploadStatus: EFileStatus.COMPLETE,
            });
          });

        task.snapshot.ref.getDownloadURL().then((downloadURL) => {
          if (this.state.mode === "add") {
            this.setState((prevState) => {
              return {
                fileUploadStatus: EFileStatus.COMPLETE,
                newCustomerDetails: {
                  ...prevState.newCustomerDetails,
                  logo: downloadURL,
                },
              };
            });
          } else {
            this.setState((prevState) => {
              return {
                fileUploadStatus: EFileStatus.COMPLETE,
                editableCustomer: {
                  ...prevState.editableCustomer,
                  logo: downloadURL,
                },
              };
            });
          }
        });
      }
    );
  };

  GeneratePassword = (): string => {
    let value = "";
    const characters =
      "!@#$%^&*ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 8; i++) {
      value += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return value;
  };

  /**
   * @function createNewCustomer
   * @param {array} customerLocationsArray json array of objects from csv uploaded file.
   * Refer to customerLocation data structure for json object.
   * Create new customer collection entry in firestore
   */
  createNewCustomer = (customerLocationsArray: ICustomerLocations[]) => {
    this.setState({
      customerLoader: true,
    });

    const createFirebaseCustomer = () => {
      const request = this.state.newCustomerDetails;
      const password = this.GeneratePassword();
      delete request.id;
      this.props.firebase
        .customers()
        .add(request)
        .then((response) => {
          if (customerLocationsArray) {
            const customer = {
              id: response.id,
              logoUrl: request.logo,
              name: request.companyName,
              paymentEmail: request.paymentEmail,
            };
            this.createNewCustomerLocation(customerLocationsArray, customer);
          }
          return response.id;
        })
        .then(() => {
          this.handleModalClose();
          this.getTableData();
        })
        .catch((err) => {
          console.log("createNewCustomer ERROR", err);
        })
        .finally(() => {
          this.setState({ customerLoader: false });
          setTimeout(() => {
            this.handleNewCustomerPassword(request, password);
          }, 5000);
        });
    };

    this.setState(
      (prevState) => {
        return {
          ...prevState,
          customerLoader: true,
          newCustomerDetails: {
            ...prevState.newCustomerDetails,
            address: {
              ...prevState.newCustomerDetails.address,
              postcode: prevState.newCustomerDetails.address.postcode,
              country: "Australia", //This will need to be address later once multiple country support is integrated.
            },
          },
        };
      },
      () => createFirebaseCustomer()
    );
  };

  createNewCustomerLocation = (
    customerLocationsArray: ICustomerLocations[],
    customer
  ) => {
    const batch = this.props.firebase.batch();
    const docRef = this.props.firebase.customerLocations();

    customerLocationsArray.forEach((obj: ICustomerLocations) => {
      const additionalFields = {
        isVerified: false,
        pushToken: "",
      };

      // If obj.docRef is undefined meaning a contractor hasn't yet been
      // assigned to a customerLocation we need to generate a doc ref Id
      const id = docRef.doc();
      batch.set(id, { ...obj, ...additionalFields, customer });
    });

    batch.commit();
  };

  handleErrorMessage = (msg: string) => {
    this.setState({
      errorMessage: msg,
    });
  };

  /**
   * @function handleModalClose Used as a reset to initialise customers state
   */
  handleModalClose = () => {
    // On the basis the enduser has uploaded a logo and doesn't complete the add customer flow
    // we need to remove the logo from firebase storage.
    // if (this.state.newCustomerDetails.logo) {
    // 	this.handleFileDelete('newCustomerDetails');
    // }

    this.setState(
      {
        showCustomerAdd: false,
        showCustomerEdit: false,
        newCustomerDetails: DEFAULT_CUSTOMER,
        showWarningModal: false,
      },
      () =>
        document.body.classList.remove(
          "create-flow-open",
          "bx--body--with-modal-open"
        )
    );
    this.initCustomerDetails = DEFAULT_CUSTOMER;
  };

  updateCustomerDetails = () => {
    this.setState({ customerLoader: true });
    delete this.state.editableCustomer.id;
    const password = this.GeneratePassword();
    const previousEmail = this.state.previousCustomerEmail; 
    const updateCustomerFB = () => {
      this.props.firebase
        .customers()
        .doc(this.state.currentCustomerId)
        .update(this.state.editableCustomer)
        .then(() => {
          this.setState({ currentCustomerId: "" });
          this.handleModalClose();
          this.getTableData();    
        })
        .catch((err) => {
          this.handleErrorMessage(err.message);
        })
        .finally(() => {
          this.setState({
            customerLoader: false,
          });
          setTimeout(() => {
            if (previousEmail !== this.state.editableCustomer.serviceContactEmail) {
              this.handleNewCustomerPassword(this.state.editableCustomer, password);
            }
          },5000)
        });
    };

    this.setState(
      (prevState) => {
        return {
          ...prevState,
          customerLoader: true,
          editableCustomer: {
            ...prevState.editableCustomer,
            address: {
              ...prevState.editableCustomer.address,
              postcode: prevState.editableCustomer.address.postcode,
            },
          },
        };
      },
      () => updateCustomerFB()
    );
  };
}
const CustomersContainer = withFirebase(CustomersContainerBase);

export default CustomersContainer;
