import React from 'react';
import { withFirebase } from 'components/Firebase';
import { withRouter, Redirect, Link } from 'react-router-dom';
import * as ROUTES from 'utils/routes';
import { AuthContext } from 'components/Auth';

import { Form, FormGroup, InlineNotification, TextInput, Button, Loading } from '@carbon/ibm-security';

interface IAuthError {
	code: string;
	message: string;
}

const LoginContainer: React.FunctionComponent<any> = ({ history, firebase }) => {

	const { authUser, isAdmin } = React.useContext(AuthContext);
	const [isLoading, setLoading] = React.useState<boolean>(true);

	const [password, setPassword] = React.useState<string>('');
	const [passwordError, setPasswordError] = React.useState<boolean>(false);
	const [passwordErrorMessage, setPasswordErrorMessage] = React.useState<string>('');

	const [email, setEmail] = React.useState<string>('');
	const [emailError, setEmailError] = React.useState<boolean>(false);
	const [emailErrorMessage, setEmailErrorMessage] = React.useState<string>('');

	const [userHasAuthenticated, setUserHasAuthenticated] = React.useState<boolean>();

	React.useEffect(() => {
		document.body.classList.add('sc--login-page');
		const timer = setTimeout(() => {
			setLoading(false)
		}, 1500);
		return () => {
			clearTimeout(timer);
			document.body.classList.remove('sc--login-page')
		}
	}, [setLoading]);

	const handleError = React.useCallback((err: IAuthError) => {
		const codeType = err.code;

		if (codeType.indexOf('password') !== -1) {
			setPasswordError(true);
			setPasswordErrorMessage(err.message);
		} else {
			setEmailError(true);
			setEmailErrorMessage(err.message);
		}

	}, [setPasswordError, setEmailError, setPasswordErrorMessage, emailErrorMessage])

	const handleLogin = React.useCallback(() => {
		setLoading(true);

		firebase.doSignInWithEmailAndPassword(email, password)
			.then(() => {
				setEmail('');
				setPassword('');
				setUserHasAuthenticated(true)
			})
			.catch((err) => {
				handleError(err);
			})
			.finally(() => {
				setLoading(false);
			})

	}, [setLoading, setUserHasAuthenticated, email, password]);

	// const handleLogout = () => {
	// 	firebase.doSignOut()
	// 		.then((result) => {
	// 			console.log(result);
	// 		}).catch((err) => {
	// 			console.log(err);
	// 		});
	// }

	if (authUser && isAdmin) {
		history.push(ROUTES.ADMIN_DASHBOARD);
		return <Redirect to={ROUTES.ADMIN_DASHBOARD} />;
	}

	const isDisabled: boolean = email === '' || password === '';

	return (
		<React.Fragment>
			<div className='bx--row sc--login-form-wrapper'>
				{isLoading && (
					<Loading
						active
						description='Checking user is authenticated...'
						withOverlay
					/>
				)}

				<div className='bx--col sc--login-marketing'></div>
				<div className='bx--col-md-3'>
					<div className='login-intro'>
						<p>Log in to Simplyclik <strong>Admin</strong></p>
						<p>Enter your username and password to log into Simplyclik Admin.</p>
					</div>
					<Form
						onSubmit={handleLogin}
					>
						<FormGroup
							legendText=''
						>
							<TextInput
								id='sc--login-email'
								name='email'
								value={email}
								invalid={emailError}
								invalidText={emailErrorMessage}
								size='xl'
								labelText='Username'
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									setEmail(event.target.value),
										setEmailError(false),
										setEmailErrorMessage('')
								}}
								placeholder='yourname@example.com'
								type='text'
							/>
							<TextInput
								id='sc--login-password'
								name='password'
								value={password}
								invalid={passwordError}
								invalidText={passwordErrorMessage}
								size='xl'
								labelText='Password'
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									setPassword(event.target.value),
										setPasswordError(false),
										setPasswordErrorMessage('')
								}}
								type='password'
							/>
							<Link to={ROUTES.PASSWORD_RESET}>Forgot your password?</Link>
						</FormGroup>
						<Button
							disabled={isDisabled}
							kind='primary'
							onClick={handleLogin}
							tabIndex={0}
							type='button'>
							Login to account
								</Button>
						{/* <Button
								kind='primary'
								onClick={handleLogout}
								tabIndex={0}
								type='button'>
								Logout
								</Button> */}
					</Form>
					{userHasAuthenticated && !isAdmin && (
						<InlineNotification
							hideCloseButton={true}
							statusIconDescription=''
							kind='error'
							notificationType='inline'
							role='alert'
							subtitle="Sorry, user doesn' t have administrative privileges to access simplyclik."
							title='Uh ohh...'
						/>
					)}
				</div>
			</div>
		</React.Fragment>
	)
}

export default withRouter(withFirebase(LoginContainer));
