/* eslint-disable no-useless-escape */
import React from 'react';
import { LeadsContext } from '../LeadsContext';
import { FirebaseContext } from 'components/Firebase';

import { RadioButtonGroup, RadioButton, InlineNotification } from '@carbon/ibm-security';
import { ELeadsReducer } from '../LeadsReducer';
import { LeadsContactInformation } from '../LeadsUtils';

const Convert: React.FunctionComponent = () => {
	const { state, dispatch } = React.useContext(LeadsContext);
	const firebaseCtx = React.useContext(FirebaseContext);

	const handleRadioButtonChange = React.useCallback((radioSelectedValue: string) => {
		dispatch({
			type: ELeadsReducer.ConvertLead,
			value: radioSelectedValue
		})
	}, [ dispatch ]);

	return (
		<div className='sc--createflow-step details leads-convert'>
			<div className='sc--createflow-column'>
				<LeadsContactInformation />
			</div>
			<div className='sc--createflow-column'>
				<div className={`sc--convert-title title`}>Convert lead</div>
				<InlineNotification
					className='leads-details-follow-up-notification'
					hideCloseButton
					statusIconDescription='Info'
					kind='info'
					notificationType='inline'
					role='alert'
					subtitle='Convert lead to a customer or contractor. Additional information is required to save.'
					title=''
				/>
				<RadioButtonGroup
					className={`sc-convert-action-radio-buttons`}
					labelPosition='right'
					legend='Group Legend'
					name='radio-button-group'
					onChange={handleRadioButtonChange}
					orientation='vertical'
					valueSelected={state.convertLead}
				>
						<RadioButton
							className={`sc--convert-action-radio-button`}
							id={`sc--convert-action-radio-button-customer`}
							labelText={'Customer'}
							value={'customer'}
						/>
						<RadioButton
							className={`sc--convert-action-radio-button`}
							id={`sc--convert-action-radio-button-contractor`}
							labelText={'Contractor'}
							value={'contractor'}
						/>
				</RadioButtonGroup>

			</div>
		</div>
	);
};

export { Convert };
