/* eslint-disable no-useless-escape */
import * as React from 'react';
import { TextInput, TextInputSkeleton } from '@carbon/ibm-security';
import ContractorsContext from '../ContractorsContext';
import { FirebaseContext } from 'components/Firebase';

import OneTimePasscode, { IOneTimePasscodeProps } from 'components/OneTimePasscode';
import { RegexFormat } from 'utils/common';
import { isEqual } from 'lodash';

const CUSTOMER_ADD = 'contractor-add';
const STEP_PREFIX = 'sc--location';

//TODO: validation for required fields. If fields are valid add description with dynamic values from input fields

interface IManageProps {
	handleServiceEmailInvalid: (isValid: boolean) => void;
	currentServiceContactEmail: string;
	docReferencePath?: string;
	hasValidated?: boolean;
	handleServiceEmail?: (value: string) => void;
}

const Manage: React.FunctionComponent<IManageProps> = (props) => {
	const contractorCtx = React.useContext(ContractorsContext);
	const firebaseCtx = React.useContext(FirebaseContext);
	const [isEmailValid, setIsEmailValid] = React.useState<boolean>(false);
	const [emailErrorMessage, setEmailErrorMessage] = React.useState<string>('');
	const [inputLoader, setInputLoader] = React.useState<boolean>(false);

	const validateEmail = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const email = event.target.value;
		const name = event.target.name;
		if((RegexFormat.email).test(email)){
			if(firebaseCtx && !isEqual(email, props.currentServiceContactEmail)){
				setInputLoader(true);
				firebaseCtx.auth.fetchSignInMethodsForEmail(event.target.value)
				.then((response) => {
					if(response.length){
						props.handleServiceEmailInvalid(true)
						setIsEmailValid(true)
						setEmailErrorMessage('The email address is already in use by another account.');
					} else {
						props.handleServiceEmailInvalid(false)
						contractorCtx.handleContractorServiceEmail(email, name);
					}
				}).catch((err) => {
					console.log(err)
				})
				.finally(() => {
					setInputLoader(false)
				});
			} else {
				props.handleServiceEmailInvalid(false);
				contractorCtx.handleContractorServiceEmail(email, name);
			}
		} else {
			setIsEmailValid(true)
			setEmailErrorMessage('A valid email address is required');
		}

	}, [setIsEmailValid, setEmailErrorMessage, setInputLoader]);

	const handleEmailInput = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setEmailErrorMessage('');
		setIsEmailValid(false)
		props.handleServiceEmail && props.handleServiceEmail(event.target.value);

	}, [setEmailErrorMessage, setIsEmailValid, setInputLoader])

	const oneTimePasscodeProps: IOneTimePasscodeProps = {
		name: contractorCtx.details.serviceContactName,
		collectionId: props.docReferencePath as string,
		hasValidated: props.hasValidated as boolean,
		serviceType: 'Contractor',
		handleRenewOtp: contractorCtx.handleRenewOtp
	}

	return (
		<div className='sc--createflow-step location'>
			<div className='sc--createflow-column'>
				<div>
					<div className={`${STEP_PREFIX}-title title`}>{'User setup'}</div>
					<p className={'sc--body'}>
						Provide the name and email address who will be able contacted when
						service requests are received.
					</p>
				</div>
				<TextInput
					{...{
						className: 'bx--col-lg-6',
						labelText: 'Service contact name *',
						id: `${CUSTOMER_ADD}--service-contact-name`,
						name: 'serviceContactName',
						onChange: contractorCtx.handleContractorInputChange,
						value: contractorCtx.details.serviceContactName,
						required: false
					}}
				/>
					<div className='bx--row'>
						<div className='bx--col-md-6 bx--col-lg-6'>
						{inputLoader ? (
							<TextInputSkeleton />
						) : (
							<TextInput
								{...{
									labelText: 'Service contact email *',
									id: `${CUSTOMER_ADD}--service-contact-email`,
									name: 'serviceContactEmail',
									onChange: handleEmailInput,
									onBlur: validateEmail,
									invalid: isEmailValid,
									invalidText: emailErrorMessage,
									value: props.currentServiceContactEmail,
									// value: contractorCtx.details.serviceContactEmail,
									required: true
								}}
							/>
						)}
					</div>
				</div>
				<div className='bx--row'>
					<p className='sc--body description bx--col-lg-8'>
						{`A one-time passcode will be sent to ${contractorCtx.details
							.serviceContactName ||
							'the contractor'} with instructions for downloading the Simplyclik mobile application.`}
					</p>
				</div>
				{contractorCtx.mode === 'edit' && (
					<OneTimePasscode {...oneTimePasscodeProps} />
				)}
			</div>
		</div>
	);
};

export { Manage };
