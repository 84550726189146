import React from 'react';
import { ILead, ILeadsInitialState, ISetScheduleFollowUp, ISetScheduleFollowUpErrors } from './LeadsInterface';
import { LeadsReducer } from './LeadsReducer';
import { defaultScheduleFollowUp } from './LeadsUtils';


const initialState: ILeadsInitialState = {
  editView: false,
  leads: [],
  loading: true,
  editLead: {} as ILead,
  scheduleFollowUp: defaultScheduleFollowUp,
  scheduleFollowUpErrors: {
    datePickerInputError: false,
    timePickerInputError: false
  }
};

const LeadsContext = React.createContext<{ state: ILeadsInitialState; dispatch: React.Dispatch<any> }>({ state: initialState, dispatch: () => null })

const LeadsContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(LeadsReducer, initialState);


  return (
    <LeadsContext.Provider value={{ state, dispatch }}>
      {children}
    </LeadsContext.Provider>
  );
};

export {
  LeadsContext,
  LeadsContextProvider
}
