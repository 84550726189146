/* eslint-disable no-undef */
import React from 'react';
import '@carbon/charts/styles.css';
import { SimpleBarChart } from '@carbon/charts-react';

interface IBarOptions {
	title: string;
	axes: {
		left: {
			primary: boolean;
			stacked: boolean;
		};
		bottom: {
			scaleType: string;
			secondary: boolean;
		};
	};
	height: string;
}

interface IBarData {
	labels: string[];
	data?: number[];
}

interface IDataChartProps {
	barData: IBarData;
}

const DataChart: React.FunctionComponent<IDataChartProps> = (props) => {

	const { data, labels } = props.barData;

	const barOptions = {
		title: 'Activity',
		axes: {
			left: {
				primary: true
			},
			bottom: {
				scaleType: 'labels',
				secondary: true
			}
		},
		grid: {
			strokeColor: '#F3F3F3'
		},
		legend: {
			enabled: false
		},
		height: '400px'
	}

	const barData = {
		labels,
		datasets: [
			{
				label: 'Dataset 1',
				data: data && data.map((datum: number) => {
					return { value: datum }
				})
			}
		]
	};

	return (
		<React.Fragment>
			<SimpleBarChart
				data={barData}
				options={barOptions}
			/>
		</React.Fragment>
	)

}

export { IBarData, IBarOptions, DataChart }
