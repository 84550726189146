/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { withFirebase } from 'components/Firebase';
import ContractorAdd from './ContractorAdd';
import ContractorEdit from './ContractorEdit';
import ContractorsContext from './ContractorsContext';
import ContractorsTable from './ContractorsTable';
import { IContractor, IContractorBase, ICustomerLocationContractors, EMode } from './interface';
import isEqual from 'lodash/isEqual';
import { DocumentTitle } from 'components/DocumentTitle';

import ComposedModal, {
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'carbon-components-react/lib/components/ComposedModal';
import Button from '@carbon/ibm-security/lib/components/Button';
import { ILead } from 'modules/Leads/LeadsInterface';
import { EPageTitles } from 'utils/config';

interface IContractorsContainerbase {
	contractorDetailsChanged: boolean;
	contractors: IContractor[];
	contractorLoader: boolean;
	editableContractor: IContractor;
	hasData: boolean;
	mode: string;
	newContractorDetails: IContractor;
	showContractorAdd: boolean;
	showContractorEdit: boolean;
	showWarningModal: boolean;
	customerLocationContractorsArray: ICustomerLocationContractors[];
	initialCustomerLocationContractorArray: ICustomerLocationContractors[];
	contractorServiceTypes: string[];
}

interface IContractorHandlers {
	getCompanyNames: () => void;
	getCustomerLocations: (id: string, stateLocation: string) => void;
	getContractorLocations: (id: string) => void;
	getCustomerLocationContractors: IContractorsContainerbase['customerLocationContractorsArray'];
	handleCustomerLocationContractor: (customerLocationServices: ICustomerLocationContractors | undefined, customerLocationId?: string, reset?: boolean, isNewCustomer?: boolean | undefined) => void;
	handleContractorInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleContractorAddressInputChange: (event: Event) => void;
	handleContractorServices: (selectedService: string) => void;
	handleAddressPostCode: (type: string, stateId: string) => void;
	handleRenewOtp: (collectionPath: string) => boolean;
	handleContractorServiceEmail: (value: string, name: string) => void;
	mode: IContractorsContainerbase['mode'];
	getContractorServiceTypes: IContractorsContainerbase['contractorServiceTypes'];
}

export interface IAddContractorContext extends IContractorBase, IContractorHandlers { }

const DEFAULT_ADD_CONTRACTOR_SETTINGS: IContractor = {
	abn: '',
	address: {
		addressLine1: '',
		addressLine2: '',
		city: '',
		country: '',
		postcode: '',
		state: ''
	},
	companyName: '',
	contactEmail: '',
	contactName: '',
	contactPhoneNumber: '',
	id: '',
	serviceContactEmail: '',
	serviceContactName: ''
};

const pageTitle: string = 'Customers';


class ContractorsContainerBase extends React.Component<any, IContractorsContainerbase> {

	fileInstanceName: string = '';
	searchInputTimeout;
	contractors: IContractor[] = [];
	//Global used to compare changes for editable contractor fileds.
	initContractorDetails: IContractor = DEFAULT_ADD_CONTRACTOR_SETTINGS;

	constructor(props: IContractorsContainerbase) {
		super(props);
		this.state = {
			contractorDetailsChanged: false,
			contractors: [],
			contractorServiceTypes: [],
			contractorLoader: false,
			customerLocationContractorsArray: [],
			editableContractor: DEFAULT_ADD_CONTRACTOR_SETTINGS,
			hasData: false,
			initialCustomerLocationContractorArray: [],
			mode: '',
			newContractorDetails: DEFAULT_ADD_CONTRACTOR_SETTINGS,
			showContractorAdd: false,
			showContractorEdit: false,
			showWarningModal: false
		};
	}

	async componentDidMount() {
		if (this.props.location.pathname === '/contractors/contractor-add') {
			this.handleNewLeadDetails(this.props.location.state as ILead)
		}
		//Remove classes if set from other page create flow view.
		document.body.classList.remove('create-flow-open', 'bx--body--with-modal-open');
		this.getTableData();
		this.getContractorServiceTypes();
	}

	/**
	 * @function handleNewLeadDetails sets leads details when converting lead to new contractor.
	 * @param leadDetails convert lead details which is used to populate initial details fields
	 */
	handleNewLeadDetails = (leadDetails: ILead) => {
		this.handleCreateFlowView(true, 'add');
		const newLeadDetails = {
			companyName: leadDetails.company,
			contactName: `${leadDetails.firstName} ${leadDetails.lastName}`,
			contactPhoneNumber: leadDetails.contactPhoneNumber,
			contactEmail: leadDetails.email
		} as IContractor;

		this.setState((prevState) => {
			return {
				...prevState,
				newContractorDetails: {
					...prevState.newContractorDetails,
					...newLeadDetails
				}
			}
		})
	}

	setEditableContractor = (contractor: IContractor) => {
		this.initContractorDetails = contractor;
		this.setState({
			editableContractor: contractor
		}, () => this.getCustomerLocationContractors(contractor.id as string));
	};

	checkContractorOTP = (docReferencePath: string): boolean => {

		return this.props.firebase
			.oneTimePasscodes()
			.get()
			.then((querySnapshot) => {
				let hasValidated: boolean = true;
				for (const documentSnapshot of querySnapshot.docs) {
					if (documentSnapshot.data().reference.path === `${docReferencePath}`) {
						hasValidated = false;
						break;
					}
				}
				return hasValidated;
			})
			.catch((err) => {
				console.log('error', err);
			})
	}

	/**
	 * @function getCustomerLocationContractors retrieve customerLocationContractors collections
	 * from firebase that belong to a specific contractor
	 * @param contractorId string with contractor id
	 * @returns array of contractorCustomerLocation objects.
	 */
	getCustomerLocationContractors = (contractorId: string) => {
		const tempArray: any = [];
		this.props.firebase
			.customerLocationContractors()
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((documentSnapshot) => {
					if (documentSnapshot.data().contractorId === contractorId) {
						const docRefId = { docRef: documentSnapshot.id }
						tempArray.push({ ...documentSnapshot.data(), ...docRefId });
					}
				});

				this.setState((prevState) => {
					return {
						customerLocationContractorsArray: [...prevState.customerLocationContractorsArray, ...tempArray],
						initialCustomerLocationContractorArray: tempArray
					}
				}, () => this.handleCreateFlowView(true, 'edit'));
			});
	}


	getContractorLocations = (id: string) => {
		if (!id) {
			console.error('id in editable contractor object not found.');
			return;
		}
		const contractorDocRef = this.props.firebase.contractors().doc(id);
		const contractorLocationDetails: any = [];
		return this.props.firebase
			.contractorLocations()
			.where('contractor', '==', contractorDocRef)
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					// doc.data() is never undefined for query doc snapshots
					contractorLocationDetails.push(doc.data());
				});
				return contractorLocationDetails;
			})
			.catch((err) => {
				console.log(err);
			});

		// console.log('contractorLocationDetails', contractorLocationDetails);

		// return contractorLocationDetails;
	};

	getCompanyNames = () => {
		const customers: any = [];
		return this.props.firebase
			.customers()
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					const tempId = { id: doc.id, companyName: doc.data().companyName };
					customers.push(tempId);
				});
				return customers;
			});
	};

	getCustomerLocations = (customerId: string, stateLocation: string) => {
		const customerLocations: any = [];
		return this.props.firebase
			.customerLocations()
			.where('address.country', 'in', ['Australia'])
			.where('address.state', '==', stateLocation)
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((documentSnapshot) => {
					if (documentSnapshot.data() && documentSnapshot.data().customer && documentSnapshot.data().customer.id === customerId) {
						const tempObj = documentSnapshot.data()
						tempObj.customerLocationId = documentSnapshot.id;
						customerLocations.push(tempObj);
					}
				});
				return customerLocations;
			})
	}

	getTableData = () => {
		this.setState({
			hasData: false
		});

		const contractorsArray: IContractor[] = [];
		this.props.firebase
			.contractors()
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					const tempObj = Object.assign({ id: doc.id }, doc.data());
					contractorsArray.push(tempObj);
				});
				this.setState({
					contractors: contractorsArray,
					hasData: true
				});
			});

		this.contractors = contractorsArray;
	};

	getContractorServiceTypes = () => {
		this.props.firebase
			.enums()
			.get()
			.then((querySnapshot) => {
				const serviceEnums: any[] = [];
				querySnapshot.forEach((doc) => {
					serviceEnums.push(doc.data());
				});
				return serviceEnums[0];
			})
			.then((serviceEnums) => {
				const contractorServiceTypes = serviceEnums.services.map((serviceEnum) => {
					return serviceEnum.name;
				}).sort();

				this.setState({
					contractorServiceTypes
				});
			})
			.catch((error) => {
				console.log('Error getting enums', error);
			})

	}

	handleRenewOtp = (collectionPath: string): boolean => {

		if (!collectionPath) {
			return false;
		}

		const customerDocRefArray = collectionPath.split('/');

		const renewOtp = this.props.firebase.functions.httpsCallable('renewOtp');
		const data = {
			collection: customerDocRefArray[0],
			document: customerDocRefArray[1]
		}

		return renewOtp(data)
			.then(() => {
				return true;
			}).catch((err) => {
				console.log('error', err)
				return false
			});
	}

	handleTableSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		const TIME_OUT = 1000;
		const value = event.target.value;
		if (this.searchInputTimeout) {
			clearTimeout(this.searchInputTimeout);
		}

		this.setState({
			hasData: false
		})

		this.searchInputTimeout = setTimeout(() => {
			this.filterContractorByName(value);
		}, TIME_OUT);
	}

	filterContractorByName = (value: string) => {
		const filteredResults: IContractor[] = this.contractors.filter((contractor: IContractor) => {
			const companyName = contractor.companyName.toLowerCase();
			const tempValue = value.toLowerCase().trim();
			if (companyName.indexOf(tempValue) !== -1) {
				return true;
			}
			return false;
		});

		if (filteredResults.length > 0) {
			this.setState({
				contractors: filteredResults,
				hasData: true
			});
		} else {
			this.setState({
				contractors: this.contractors,
				hasData: true
			})
		}
	}

	handleContractorInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		//https://reactjs.org/docs/events.html#event-pooling
		event.persist();
		if (this.state.mode === EMode.ADD) {
			this.setState((prevState) => {
				return {
					newContractorDetails: {
						...prevState.newContractorDetails,
						[event.target.name]: event.target.value
					}
				};
			});
		} else {
			this.setState((prevState) => {
				return {
					editableContractor: {
						...prevState.editableContractor,
						[event.target.name]: event.target.value
					}
				};
			});
		}
	};

	handleContractorServiceEmail = (value: string, name: string) => {
		if (this.state.mode === EMode.ADD) {
			this.setState((prevState) => {
				return {
					newContractorDetails: {
						...prevState.newContractorDetails,
						[name]: value
					}
				};
			});
		} else {
			this.setState((prevState) => {
				return {
					editableContractor: {
						...prevState.editableContractor,
						[name]: value
					}
				};
			});
		}
	}

	handleContractorAddressInputChange = (event: Event) => {
		const target = event.target as HTMLInputElement;
		const inputType = target.name;
		const value = target.value;

		if (this.state.mode === EMode.ADD) {
			this.setState((prevState) => {
				return {
					newContractorDetails: {
						...prevState.newContractorDetails,
						address: {
							...prevState.newContractorDetails.address,
							[inputType]: value
						}
					}
				};
			});
		} else {
			this.setState((prevState) => {
				return {
					editableContractor: {
						...prevState.editableContractor,
						address: {
							...prevState.editableContractor.address,
							[inputType]: value
						}
					}
				};
			});
		}
	};

	// handleBillingOptions = (value: string) => {
	// 	if (this.state.mode === 'add') {
	// 		this.setState({
	// 			newContractorDetails: {
	// 				...this.state.newContractorDetails,
	// 				billing: value
	// 			}
	// 		});
	// 	} else {
	// 		this.setState({
	// 			editableContractor: {
	// 				...this.state.editableContractor,
	// 				billing: value
	// 			}
	// 		});
	// 	}
	// };

	handleAddressPostCode = (type: string, stateId: string) => {
		if (this.state.mode === 'add') {
			this.setState((prevState) => {
				return {
					newContractorDetails: {
						...prevState.newContractorDetails,
						address: {
							...prevState.newContractorDetails.address,
							[type]: stateId
						}
					}
				};
			});
		} else {
			this.setState((prevState) => {
				return {
					editableContractor: {
						...prevState.editableContractor,
						address: {
							...prevState.editableContractor.address,
							[type]: stateId
						}
					}
				};
			});
		}
	};

	render() {
		const { mode } = this.state;
		const contractorRows: IContractor[] = this.state.contractors;

		const addContractorCtx: IAddContractorContext = {
			details: mode === 'edit' ? this.state.editableContractor : this.state.newContractorDetails,
			getCompanyNames: this.getCompanyNames,
			getContractorLocations: this.getContractorLocations,
			getContractorServiceTypes: this.state.contractorServiceTypes,
			getCustomerLocations: this.getCustomerLocations,
			getCustomerLocationContractors: this.state.customerLocationContractorsArray,
			handleAddressPostCode: this.handleAddressPostCode,
			handleCustomerLocationContractor: this.handleCustomerLocationContractor,
			handleContractorServices: this.handleContractorServices,
			handleContractorAddressInputChange: this.handleContractorAddressInputChange,
			handleContractorInputChange: this.handleContractorInputChange,
			handleContractorServiceEmail: this.handleContractorServiceEmail,
			handleRenewOtp: this.handleRenewOtp,
			mode: mode
		};

		return (
			<ContractorsContext.Provider value={addContractorCtx}>
				<DocumentTitle title={EPageTitles.Contractors} />
				<React.Fragment>
					{this.state.contractorDetailsChanged && (
						<ComposedModal
							{...{
								className: 'sc--composed-modal',
								open: this.state.showWarningModal,
								danger: true,
								onClose: () => this.setState({ showWarningModal: false })
							}}>
							<ModalHeader
								{...{
									buttonOnClick: () => {
										this.setState({ showWarningModal: false });
									},
									iconDescription: 'Close',
									title: 'Warning'
								}}
							/>
							<ModalBody>
								<div>
									<p className='sc--composed-modal--body'>You have unsaved changes.</p>
									<p className='sc--composed-modal--body'>
										If you'd like to keep editing press the Continue editing button.
									</p>
								</div>
							</ModalBody>
							<ModalFooter>
								<Button
									{...{
										kind: 'secondary',
										onClick: () => this.setState({ showWarningModal: false })
									}}>
									{'Continue editing'}
								</Button>
								<Button
									{...{
										kind: 'danger',
										onClick: () => {
											this.handleModalClose();
										} //close modal and reset fields check file upload
									}}>
									{'Close without saving'}
								</Button>
							</ModalFooter>
						</ComposedModal>
					)}

					{this.state.showContractorAdd && (
						<ContractorAdd
							{...{
								contractorDetails: this.state.newContractorDetails,
								createNewContractor: this.createNewContractor,
								handleCreateFlowView: this.handleCreateFlowView,
								loaderState: this.state.contractorLoader
							}}
						/>
					)}
					{this.state.showContractorEdit && (
						<ContractorEdit
							{...{
								handleCreateFlowView: this.handleCreateFlowView,
								editContractorDataChanged: this.editContractorDataChanged,
								loaderState: this.state.contractorLoader,
								checkContractorOTP: this.checkContractorOTP,
								updateContractorDetails: this.updateContractorDetails
							}}
						/>
					)}
					<div className={'sc--main-title'}>
						<h1>{EPageTitles.Contractors}</h1>
					</div>
					<ContractorsTable
						{...{
							setEditableContractor: this.setEditableContractor,
							rows: contractorRows,
							loaded: this.state.hasData,
							handleCreateFlowView: this.handleCreateFlowView,
							handleTableSearch: this.handleTableSearch
						}}
					/>
				</React.Fragment>
			</ContractorsContext.Provider>
		);
	}

	checkContractorDetails = (): boolean => {
		const { newContractorDetails } = this.state;
		let hasChanged = false;
		if (!isEqual(newContractorDetails, DEFAULT_ADD_CONTRACTOR_SETTINGS)) {
			hasChanged = true;
		}

		return hasChanged;
	};

	hasCustomerLocationContractorChanged = (): boolean => {
		const { customerLocationContractorsArray, initialCustomerLocationContractorArray } = this.state;
		const hasChanged: boolean = isEqual(customerLocationContractorsArray, initialCustomerLocationContractorArray);
		return hasChanged;
	}

	hasContractorDataChanged = (): boolean => {
		const { editableContractor } = this.state;
		const hasChanged: boolean = isEqual(editableContractor, this.initContractorDetails);
		return hasChanged;
	};

	editContractorDataChanged = (): boolean => {
		let isButtonDisabled: boolean = true;

		if (!this.hasCustomerLocationContractorChanged() || !this.hasContractorDataChanged()) {
			isButtonDisabled = false;
		}
		return isButtonDisabled;
	}

	/**
 * @function handleContractorServices When checkbox value is checked we simply add that
 * selected value to an array of string services. On click functionality to toggle those
 * values using filter ternary operator.
 * @param selectedService the service selected for current customerLocation on google map.
 *
 */
	handleContractorServices = (selectedService: string): void => {
		// this.setState(prevState => {
		// 	const contractorServicesArray: string[] = prevState.newContractorDetails.services.includes(
		// 		selectedService
		// 	)
		// 		? prevState.newContractorDetails.services.filter(
		// 			i => i !== selectedService
		// 		)
		// 		: [...prevState.newContractorDetails.services, selectedService];
		// 	return {
		// 		newContractorDetails: {
		// 			...prevState.newContractorDetails,
		// 			services: contractorServicesArray
		// 		}
		// 	};
		// });
	};

	/**
	 * @function handleCustomerLocationContractor
	 * @param customerLocationServices customerLocation id and selected services for each location
	 * @param {optional} customerLocationId string used to remove customerLocationContractor object from array
	 *  This collects the services enabled for each customerLocation, ready for a batch update on the
	 *  customerLocationContractor collection in firebase.
	 */
	handleCustomerLocationContractor = (customerLocationServices: ICustomerLocationContractors | undefined, resetCustomerLocationId?: string, remove?: boolean) => {
		const { customerLocationContractorsArray } = this.state;
		let tempContractorLocation: ICustomerLocationContractors[] = customerLocationContractorsArray;

		if(remove) {
			tempContractorLocation = tempContractorLocation.filter((contractorLocation: ICustomerLocationContractors) => {
				if(Array.isArray(contractorLocation.customerLocationId) && contractorLocation.customerLocationId[0] === resetCustomerLocationId){
					return contractorLocation.customerLocationId[0] !== resetCustomerLocationId
				}
				return contractorLocation.customerLocationId !== resetCustomerLocationId
			});
		} else {

			const customerLocationContractor = { ...this.getContractorDetails(), ...customerLocationServices as ICustomerLocationContractors }
			let isNewCustomerLocation: boolean = true;

			for(let i = 0; i < tempContractorLocation.length; i++){
				if(isEqual(tempContractorLocation[i].customerLocationId, customerLocationServices?.customerLocationId)) {
					tempContractorLocation[i] = { ...tempContractorLocation[i], ...customerLocationContractor };
					isNewCustomerLocation = false;
					break;
				} else {
					isNewCustomerLocation = true;
				}
			}

			if(isNewCustomerLocation){
				tempContractorLocation.push(customerLocationContractor)
			}
		}

		const customerLocations = tempContractorLocation
		.map((item) => {
			if(Array.isArray(item.customerLocationId)){
				return item.customerLocationId.map((id: string) => {
					return this.props.firebase.customerLocations().doc(id);
				})
			}
			return this.props.firebase.customerLocations().doc(item.customerLocationId);
		})
		.reduce((acc, val) => acc.concat(val), []);

		this.setState((prevState) => {
			return {
				...prevState,
				customerLocationContractorsArray: tempContractorLocation,
				editableContractor: {
					...prevState.editableContractor,
					customerLocations
				}
			}
		})
	}

	handleCreateFlowView = (show: boolean, mode: string) => {
		!show ? document.body.classList.remove('create-flow-open', 'bx--body--with-modal-open') : document.body.classList.add('create-flow-open');


		if (mode === EMode.ADD) {
			if (show === false) {
				if (this.checkContractorDetails()) {
					this.setState({
						contractorDetailsChanged: true,
						showWarningModal: true
					});
				} else {
					this.setState({
						mode: '',
						showContractorAdd: false
					}, () => {
						this.props.history.push('/contractors')
					});
				}
			} else {
				this.setState({
					mode: mode,
					showContractorAdd: true
				});
			}
		}

		if (mode === EMode.EDIT) {
			if (show === false) {
				if (!isEqual(this.state.editableContractor, this.initContractorDetails)) {
					this.setState({
						contractorDetailsChanged: true,
						showWarningModal: true
					});
				} else {
					this.setState({
						customerLocationContractorsArray: [],
						initialCustomerLocationContractorArray: [],
						editableContractor: this.initContractorDetails = DEFAULT_ADD_CONTRACTOR_SETTINGS,
						mode: '',
						showContractorEdit: false
					});
				}
			} else {
				this.setState({
					mode: mode,
					showContractorEdit: true
				});
			}
		}
	};

	/**
	 * @function createNewContractor
	 * Create new contractor collection entry in firestore
	 */
	createNewContractor = () => {
		const request = this.state.newContractorDetails;
		delete request.id;

		this.setState({
			contractorLoader: true
		});

		this.props.firebase
			.contractors()
			.add(request)
			.then((docRef) => {
				if (this.state.customerLocationContractorsArray.length) {
					const id = docRef.id;
					this.createCustomerLocationContractors(id);
				}
			})
			.then(() => {
				this.handleModalClose();
				this.getTableData();
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				this.setState({
					contractorLoader: false
				});
			});

	};

	/**
	 * @function handleModalClose Used as a reset to initialise contractors state
	 */
	handleModalClose = () => {
		this.setState({
			customerLocationContractorsArray: [],
			initialCustomerLocationContractorArray: [],
			showContractorAdd: false,
			showContractorEdit: false,
			newContractorDetails: DEFAULT_ADD_CONTRACTOR_SETTINGS,
			showWarningModal: false
		}, () => {
			document.body.classList.remove('create-flow-open', 'bx--body--with-modal-open');
			this.props.history.push('/contractors')
		});
		this.initContractorDetails = DEFAULT_ADD_CONTRACTOR_SETTINGS;
	};

	/**
	 * @function createCustomerLocationContractors add new contractors details and provided services
	 * to customerLocationContractors collection using firebase batch set
	 * @param {string} id The newly created contractors assigned id
	 */
	createCustomerLocationContractors = (id: string) => {

		const { newContractorDetails } = this.state;
		const { customerLocationContractorsArray } = this.state;
		const newContractor: ICustomerLocationContractors = {
			contractorContactEmail: newContractorDetails.contactEmail,
			contractorContactName: newContractorDetails.contactName,
			contractorContactPhone: newContractorDetails.contactPhoneNumber,
			contractorCompanyName: newContractorDetails.companyName,
			contractorId: id
		}

		const batch = this.props.firebase.batch();
		const docRef = this.props.firebase.customerLocationContractors()

		customerLocationContractorsArray.forEach((obj: ICustomerLocationContractors) => {
			const id = docRef.doc().id;
			batch.set(docRef.doc(id), { ...obj, ...newContractor });
		});

		batch.commit();
	}

	/**
	 * @function getContractorDetails Used to retrieve current contractor details
	 * in edit mode. These values are used to set customerLocationContractor details
	 * for customer locations that haven't been set for that contractor yet.
	 */
	getContractorDetails = () => {
		const { editableContractor } = this.state;
		return {
			contractorContactName: editableContractor.contactName,
			contractorContactEmail: editableContractor.contactEmail,
			contractorContactPhone: editableContractor.contactPhoneNumber,
			contractorCompanyName: editableContractor.companyName,
			contractorId: editableContractor.id
		}
	}

	/**
	 * @function updateCustomerLocationContractor To batch set of contractors services to
	 * a customers location or multiple customer location collections. Using firebase batch set commit.
	 * Ref: https://firebase.google.com/docs/firestore/manage-data/transactions
	 */
	updateCustomerLocationContractor = () => {
		const { customerLocationContractorsArray } = this.state;
		const batch = this.props.firebase.batch();
		const docRef = this.props.firebase.customerLocationContractors();

		const docsToDelete = this.state.initialCustomerLocationContractorArray.filter((obj, index) => {
			return !(customerLocationContractorsArray[index] && customerLocationContractorsArray[index].docRef);
		});

		customerLocationContractorsArray.forEach((obj: ICustomerLocationContractors) => {
			// If obj.docRef is undefined meaning a contractor hasn't yet been
			// assigned to a customerLocation we need to generate a doc ref Id
			const id = obj.docRef || docRef.doc().id;

			// Delete the docRef key from object as it's used only to reference that collections UID
			delete obj.docRef;

			batch.set(docRef.doc(id), obj)
		});

		if (docsToDelete.length) {
			docsToDelete.forEach((obj: ICustomerLocationContractors) => {
				batch.delete(docRef.doc(obj.docRef));
			})
		}

		batch.commit();
	}

	/**
	 * @function updateContractorDetails update contractor details
	 */
	updateContractorDetails = () => {
		const { id, ...tempEditableContractor } = this.state.editableContractor;

		this.setState({
			contractorLoader: true
		});

		this.props.firebase
			.contractors()
			.doc(id)
			.update(tempEditableContractor)
			.then(() => {
				if (!isEqual(this.state.customerLocationContractorsArray, this.state.initialCustomerLocationContractorArray)) {
					this.updateCustomerLocationContractor();
				}
				return;
			})
			.then(() => {
				this.handleModalClose();
				this.getTableData();
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				this.setState({
					contractorLoader: false
				});
			});
	};
}
const ContractorsContainer = withFirebase(ContractorsContainerBase);

export default ContractorsContainer;
